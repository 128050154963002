import { Pipe, PipeTransform } from "@angular/core";
import { LocationForRoute } from "src/app/models";

@Pipe({
  name: "location",
})
export class LocationPipe implements PipeTransform {
  constructor() {}

  transform(locations: LocationForRoute[], locationType: string): any {
    if (!locations) locations = [];
    if (!locationType) return locations;

    const routeDestinationType = locationType == "5" ? 2 : 1;
    return locations.filter((x) => !x.Type || x.Type === +routeDestinationType);
  }
}
