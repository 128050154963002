import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup } from "@angular/forms";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Router } from "@angular/router";

// MODELOS
import {
  AppConstants,
  CompanyAndMail,
  MailDataModel,
  CompanyInfoModel,
  CompanyListResponse,
  MailDataModelResponse,
  CompanyResponse,
  SapConResponse,
  LogoCompanyResponse,
  CompanyUDFsResponse,
  CompanyUDF,
  BaseResponse,
  ApiResponse,
  HardCodedReport,
} from "./../models/index";

// RUTAS

// COMPONENTES

// SERVICIOS
import { AlertService } from "./alert.service";
import { StorageService } from "./storage.service";
import { Observable } from "rxjs";
import { ISetting } from '../models/i-setting';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService
  ) {}

  GetCompanys() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.get<CompanyListResponse>(
      `${environment.apiUrl}api/Company/GetCompanysList`,
      { headers }
    );
  }

  GetCompany(Id: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.get<CompanyResponse>(
      `${environment.apiUrl}api/Company/GetCompany?Id=${Id}`,
      { headers }
    );
  }

  GetMailInfo(Id: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.get<MailDataModelResponse>(
      `${environment.apiUrl}api/Company/GetMailInfo?Id=${Id}`,
      { headers }
    );
  }

  GetSapCon() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.get<SapConResponse>(
      `${environment.apiUrl}api/Company/GetSapConList`,
      { headers }
    );
  }

  CreateCompanyAndMail(
    _companyAndMail: CompanyAndMail,
    _logoFile: File
  ):Observable<BaseResponse> {

    let apiUrl: string;

    if (!_companyAndMail.company.Id) {
      apiUrl = `${environment.apiUrl}api/Company/CreateCompany`;
    } else {
      apiUrl = `${environment.apiUrl}api/Company/UpdateCompany`;
    }

    const formData = new FormData();
    formData.append("file", _logoFile);
    formData.append("companyAndMail", JSON.stringify(_companyAndMail));

    return this.http.post<BaseResponse>(apiUrl, formData);
  }


  CreateCompanyMailModel(
    companyMailForm: FormGroup,
    companyId: number,
    mailDataId: number
  ) {
    let company: CompanyInfoModel = {
      Id: companyId !== null ? companyId : 0,
      DBName: companyMailForm.value.DBName,
      DBCode: companyMailForm.value.DBCode,
      SAPConnectionId: companyMailForm.value.SAPConnectionId,
      Active: companyMailForm.value.Active,
      CheckInTime: companyMailForm.value.CheckInTime,
      SendMails: companyMailForm.value.Mails,
      LogoPath: "",
      MailDataId: mailDataId !== null ? mailDataId : 0,
      SapCon: "",
      Warehouses: companyMailForm.value.Warehouses,
      BillingRange: companyMailForm.value.BillingRange,
      HasFreight: companyMailForm.value.HasFreight,
      HasHeaderDiscount: companyMailForm.value.HasHeaderDiscount,
      HasMixedMode: !companyMailForm.value.HasMixedMode
    };

    let email: MailDataModel = {
      Id: mailDataId !== null ? mailDataId : 0,
      Host: companyMailForm.value.Host,
      from: companyMailForm.value.from,
      pass: companyMailForm.value.pass,
      SSL: companyMailForm.value.SSL,
      port: companyMailForm.value.port !== "" ? companyMailForm.value.port : 0,
      subject: companyMailForm.value.subject,
      user: companyMailForm.value.user,
    };

    let result: CompanyAndMail = {
      company: company,
      mail: email,
    };

    return result;
  }

  // funcion para obtener el logo de la compañia de la DBLocal
  // no recibe parametros
  GetCompanyLogo(userMappId: number) {
    const url = `${environment.apiUrl}api/Company/GetCompanyLogo?userMappId=${userMappId}`;

    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.get<LogoCompanyResponse>(url, { headers });
  }

  GetCompanyUDFs() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.get<CompanyUDFsResponse>(
      `${environment.apiUrl}api/Company/GetCompanyUDFs?userMappId=${TOKEN.userMappId}&lang=es`,
      { headers }
    );
  }

  handlePostOrPutCompanyUDF(UDF: CompanyUDF) {
    const URL =
      UDF.Id > 0 ? "api/Company/PutUDF" : "api/Company/PostCompanyUDFs";
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.post<BaseResponse>(
      `${environment.apiUrl}${URL}?userMappId=${TOKEN.userMappId}`,
      UDF,
      { headers }
    );
  }

  GetUDFsToMapp(category: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.get<CompanyUDFsResponse>(
      `${environment.apiUrl}api/Company/GetAllUDF?userMappId=${TOKEN.userMappId}&category=${category}`,
      { headers }
    );
  }

  DeleteUDF(UDFId: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.delete<BaseResponse>(
      `${environment.apiUrl}api/Company/DeleteUDF?Id=${UDFId}`,
      { headers }
    );
  }

  GetKeyReportManager() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.get<ApiResponse<number>>(
      `${environment.apiUrl}api/Company/GetKeyReportManager`,
      { headers }
    );
  }

  private XHRWithCallBack(){

  }

  GetHardCodedReportTypes() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.get<ApiResponse<HardCodedReport[]>>(
      `${environment.apiUrl}api/Documents/GetHardCodedReportTypes`,
      { headers }
    );
  }

  GetHardCodedReports(_companyId: number): Observable<ApiResponse<HardCodedReport[]>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.get<ApiResponse<HardCodedReport[]>>(
      `${environment.apiUrl}api/Documents/GetHardCodedReports?companyId=${_companyId}`,
      { headers }
    );
  }

  UpdateHardCodedReports(formData: FormData, _companyId: number, alertService: AlertService) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    let XHR = new XMLHttpRequest();
    this.blockUI.start(`Actualizando documentos de reportes...`)
    XHR.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        let response = JSON.parse(XHR.responseText);
        if (response.result) {
          alertService.ToastSuccessAlert("Reportes guardados correctamente");
        } else {
          alertService.ErrorAlert(
            "Error al subir reportes" +
              response.errorInfo.Message
          );
        }
      }
    };
    this.blockUI.stop(); // Stop blocking
    XHR.open("POST", `${environment.apiUrl}api/Documents/UpdateHardCodedReports?companyId=${_companyId}`, true);
    XHR.setRequestHeader("Authorization", `Bearer ${TOKEN.access_token}`);
    XHR.send(formData);
  }

  DownloadHardCodedReport(ReportType: number, _companyId: number) {
    if(!_companyId) return;
    
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.get<ApiResponse<string>>(
      `${environment.apiUrl}api/Documents/DownloadHardCodedReportFile?ReportType=${ReportType}&companyId=${_companyId}`,
      { headers }
    );
  }

  GetSettings(): Observable<ApiResponse<ISetting[]>>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.get<ApiResponse<ISetting[]>>(
      `${environment.apiUrl}api/Settings/GetSettings`,
      { headers }
    );
  }

  StoreSetting(setting: ISetting): Observable<BaseResponse>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.post<BaseResponse>(
      `${environment.apiUrl}api/Settings/StoreSetting`, 
      setting,
      { headers }
    );
  }

  GetSettingByCode(code: string): Observable<ApiResponse<ISetting>>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.get<ApiResponse<ISetting>>(
      `${environment.apiUrl}api/Settings/GetSettingByCode?code=${code}`,
      { headers }
    );
  }

}
