import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { RolsModel } from 'src/app/models';
import { RolService, AlertService } from 'src/app/services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-rols',
  templateUrl: './rols.component.html',
  styleUrls: ['./rols.component.css']
})
export class RolsComponent implements OnInit {
  G_CreateRolByUserAssign: boolean; 
  G_CrtRol: boolean;
  G_UptRol: boolean;
  G_UptPerms: boolean;
  activeTap: string = 'tabList';
  saveRolPermsAction: EventEmitter<boolean>;
  createRolAssignAction: EventEmitter<boolean>;
  @BlockUI() blockUI: NgBlockUI;
  rols: RolsModel[];
  rolInEdition: RolsModel;
  rolModal: NgbModalRef;
  G_AssignRolPerms: boolean;
  G_UnassignRolPerms: boolean;

  constructor(private rolService: RolService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private globals: Globals) { 

     
    }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.getRols();
    this.rolInEdition = null;
    this.createRolAssignAction = new EventEmitter<boolean>();
    this.saveRolPermsAction = new EventEmitter<boolean>();
    this.G_AssignRolPerms = this.globals.perms.some(p => p.Name === 'G_AssignRolPerms');
    this.G_UnassignRolPerms = this.globals.perms.some(p => p.Name === 'G_UnassignRolPerms');
    this.G_CreateRolByUserAssign = this.globals.perms.some(x => x.Name === 'G_CreateRolByUserAssign');
    this.G_UptPerms = this.globals.perms.some(p => p.Name === 'G_UptPerms');
    this.G_CrtRol = this.globals.perms.some(p => p.Name === 'G_CrtRol');
    this.G_UptRol = this.globals.perms.some(p => p.Name === 'G_UptRol');
  }

  getRols() {
    this.rols = [];

    this.rolService.getRoles().subscribe((data) => {
      if (data.result) {
        this.rols = data.RolsList;
      } else {
        this.alertService.ErrorAlert(`${data.errorInfo.Message}`);
      }
    }, error => {
      this.alertService.ErrorAlert(`${error}`);
    });
  }

  onClickRolModal(rolModal: any) {
    if (!this.rolInEdition) {
      this.rolInEdition = {
        Active: true,
        Id: 0,
        Name: null
      };
    }

    this.rolModal = this.modalService.open(rolModal, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
    this.rolModal.result.then(() => {
      this.rolInEdition = null;
    });
  }

  onClickCreateRol() {
    this.blockUI.start();
    this.rolService.handlePostOrPutRol(this.rolInEdition).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
        this.initializePage();
        this.onClickDismissModal();
      } else {
        this.alertService.ErrorAlert(response.errorInfo.Message);
      }
    }, err => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(err);
    });
  }

  onClickEditRol(rolModal: any, rol: RolsModel) {
    this.rolInEdition = { ...rol };

    this.rolModal = this.modalService.open(rolModal, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
    this.rolModal.result.then(() => this.rolInEdition = null);
  }

  onClickDismissModal() {
    this.rolModal.close({});
  }

  OnTabChange(_event: any) {
    this.activeTap = _event.nextId;
  }
}
