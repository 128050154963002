import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

// MODELOS
import { HardCodedReport, MailDataModel, SapConModel, MailDataModelResponse, CompanyResponse, CompanyInfoModel, CompanyListResponse, BaseResponse } from '../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { CompanyService, AlertService } from '../../services/index';
import { Globals } from 'src/app/globals';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ISetting, SettingKeyValue, IReportManagerSetting } from '../../models/i-setting';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-company-conf',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  // varbox
  reportManagerSettings: IReportManagerSetting[];
  settingFormBySettingKey: {[key: string] : string};
  saveDiscountHerarchies: EventEmitter<boolean>;
  settings: ISetting[];

  RptMngSettingForm: FormGroup;

  LogSettingsForm: FormGroup;
  LogSettingsModal: NgbModalRef;

  SchedulingSettingsForm: FormGroup;
  SchedulingSettingsModal: NgbModalRef;

  ValidateInvSettingForm: FormGroup;
  ValidateInvSettingModal: NgbModalRef;

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('ngbTabset') tabset;
  companyId: number;
  mailDataId: number;
  companyMailForm: FormGroup;
  title: string;
  create: boolean;
  sapConnectionList: SapConModel[] = [];
  selectedFile: File = null;
  imgFileName: string;
  logoPath: string;
  G_CreateCompany: boolean;
  G_EditCompany: boolean;
  companyList: CompanyInfoModel[] = [];
  useServiceLayer: FormControl;

  //Vars Reports
  HardCodedReports: HardCodedReport[];
  ModalHardCodedReport: NgbModalRef;
  HardCodedReportFiles: {[id: string] : any} = {};
  closeResult: string;


  constructor(private fb: FormBuilder,
    private globals: Globals,
    private companyService: CompanyService,
    private alertService: AlertService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.useServiceLayer = new FormControl();
    this.useServiceLayer.setValue(false);
    this.companyId = 0;
    this.G_CreateCompany = this.globals.perms.some(x => x.Name === 'G_CreateCompany');
    this.G_EditCompany = this.globals.perms.some(x => x.Name === 'G_EditCompany');
    this.mailDataId = 0;
    this.RptMngSettingForm = this.fb.group({
      ApplicationKey: [0, Validators.required],
      CompanyId: [0, Validators.required]
    });
    this.ChargeCompanyList();
    this.setNewFormData();
    this.LoadSettings();
    this.setData();
    this.chargeSapConList();
    this.InitHardCodedReports();
    this.ResetLogSettingsForm();
    this.ResetSchedulingSettingsForm();

    this.ValidateInvSettingForm = this.fb.group({
      OINV: [false],
      ORDR: [false],
      OQUT: [false],
      ODLN: [false]
    });

    this.reportManagerSettings = [];

    this.settingFormBySettingKey = {};
    this.settingFormBySettingKey["ValidateInventory"] = 'ValidateInvSettingForm';
    this.settingFormBySettingKey["Scheduling"] = 'SchedulingSettingsForm';
    this.settingFormBySettingKey["LogMobileSettings"] = 'LogSettingsForm';
    this.settingFormBySettingKey["ReportManager"] = 'RptMngSettingForm';

    this.saveDiscountHerarchies = new EventEmitter<boolean>();
  }

  get fCM() { return this.companyMailForm.controls; }

  ChargeCompanyList() {
    this.blockUI.start();
    this.companyService.GetCompanys().subscribe((data: CompanyListResponse) => {
      this.blockUI.stop();
      if (data.result) {
        this.companyList = data.CompanyList;
      } else {
        this.alertService.WarningAlert(`${data.errorInfo.Message}`);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`${error}`);
    });
  }

  setNewFormData() {
    this.companyMailForm = this.fb.group({
      DBName: ['', [Validators.required, Validators.minLength(1)]],
      DBCode: ['', [Validators.required, Validators.minLength(1)]],
      SAPConnectionId: [''],
      CheckInTime: ['', Validators.required],
      Warehouses: ['', Validators.required],
      Active: [false],
      Mails: [false],
      subject: [''],
      from: [''],
      user: [''],
      pass: [''],
      port: [''],
      Host: [''],
      SSL: [false],
      BillingRange: [''],
      useBillingRange: [false],
      HasFreight: [false],
      HasMixedMode: [false],
      HasHeaderDiscount: [false]
    });
  }

  setData() {
    if (!this.companyId) {
      this.create = true;
      this.title = 'Crear';
    } else {
      this.create = false;
      this.title = 'Editar';
      this.GetCompany(this.companyId);
    }
  }

  GetCompany(Id: number) {
    this.blockUI.start();
    this.companyService.GetCompany(Id)
      .subscribe((data: CompanyResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.ChargeCompanyInfo(data.Company);
        } else {
          this.alertService.ToastWarningAlert(`${data.errorInfo.Message}`);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.ToastErrorAlert(`${error}`);
      });
  }

  ChargeCompanyInfo(company: CompanyInfoModel) {

    if (company.LogoPath && company.LogoPath !== '') {
      this.logoPath = company.LogoPath;
      const tempLogo = company.LogoPath.substring(company.LogoPath.lastIndexOf('\\'), company.LogoPath.length);
      this.imgFileName = tempLogo.substring(1, tempLogo.length);
    }
    this.companyMailForm.patchValue({
      DBName: company.DBName,
      DBCode: company.DBCode,
      SAPConnectionId: company.SAPConnectionId,
      Active: company.Active,
      CheckInTime: company.CheckInTime,
      Warehouses: company.Warehouses,
      Mails: company.SendMails,
      BillingRange: company.BillingRange,
      useBillingRange: company.BillingRange ? true : false,
      HasFreight: company.HasFreight,
      HasMixedMode: !company.HasMixedMode,
      HasHeaderDiscount: company.HasHeaderDiscount
    });
    this.seriesFormControlValidators(this.fCM.useBillingRange, this.fCM.BillingRange);
    this.GetMailInfo(company.MailDataId);
    this.RptMngSettingForm.get('CompanyId').patchValue(this.companyId);
  }

  GetMailInfo(Id: number) {
    this.blockUI.start();
    this.companyService.GetMailInfo(Id)
      .subscribe((data: MailDataModelResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.ChargeMailInfo(data.mailData);
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  ChargeMailInfo(mail: MailDataModel) {
    this.companyMailForm.patchValue({
      subject: mail.subject,
      from: mail.from,
      user: mail.user,
      pass: mail.pass,
      port: mail.port,
      Host: mail.Host,
      SSL: mail.SSL
    });
  }

  chargeSapConList() {
    this.blockUI.start();
    this.companyService.GetSapCon().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.sapConnectionList = data.SapConList;
        this.companyMailForm.patchValue({ SAPConnectionId: this.sapConnectionList[0].Id });
      } else {
        this.alertService.ErrorAlert(`${data.errorInfo.Message}`);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`${error}`);
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.companyMailForm.invalid) {
      this.alertService.ToastWarningAlert('El formulario de compañía contiene errores');
      return;
    }

    this.blockUI.start(); // Start blocking

    let companyAndMail =  this.companyService.CreateCompanyMailModel(this.companyMailForm, this.companyId, this.mailDataId);
    companyAndMail.company.LogoPath = this.logoPath || '';

    this.SaveServiceLayerSettings();
    this.companyService.CreateCompanyAndMail(companyAndMail, this.selectedFile)
    .pipe(finalize(() => this.blockUI.stop()))
    .subscribe({
      next: (callback) => {
        if(callback.result)
        {
          if(callback.errorInfo)
          {
            this.alertService.ToastInfoAlert(callback.errorInfo.Message);
          }
          else
          {
            this.alertService.ToastSuccessAlert("Compañía creada con éxito");
            this.tabset.activeId = 'tabList';
            this.setNewFormData();
            this.ChargeCompanyList();
          }
        }
        else
        {
          this.alertService.ErrorAlert(callback.errorInfo.Message);
        }
      },
      error: (error) => {
        console.error(error);
        this.alertService.ErrorAlert(error);
      }
    });
  }

  // funcion para seleccionar un archivo a guardar
  onFileSelected(event) {
    let file = <File>event.target.files[0];
    let ext = this.GetFileExtension(file.name);

    if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
      this.selectedFile = file;
      this.imgFileName = this.selectedFile.name;
    } else {
      this.alertService.InfoAlert('Formato de archivo no soportado');
    }
  }

  GetFileExtension(fileName: string) {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }

  // valida si el formgroup toChange debe ser requerido o no dependiendo del valor de toValidate
  seriesFormControlValidators(toValidate: any, toChange: any) {
    if (toValidate.value) {
      toChange.setValidators(Validators.required);
    } else {
      toChange.clearValidators();
    }
    toChange.updateValueAndValidity();
  }

  // si el FormControl con el número de serie está vacío
  // desactivamos el check que lo marca como requerido
  seriesInputChange(serieNumber: any, serieRequired: any) {
    if (serieNumber.errors) {
      serieRequired.setValue(false);
      this.seriesFormControlValidators(serieRequired, serieNumber);
    }
  }

  deleteIcon() {
    this.logoPath = undefined;
    this.selectedFile = undefined;
    this.imgFileName = '';
  }

  onTabChange(event) {
    if (event.nextId === 'tabList') {
      this.companyId = 0;
      this.create = true;
      this.title = 'Crear';
      this.imgFileName = '';
      this.setNewFormData();
      this.useServiceLayer.patchValue(false);
      this.RptMngSettingForm.reset(0);
    }
  }

  onClickEditCompany(Id: number) {
    this.companyId = Id;
    this.tabset.activeId = 'tabCrud';
    this.create = false;
    this.title = 'Guardar';
    this.GetCompany(this.companyId);
  }

  // Sección de Hard Coded Reports

  InitHardCodedReports(): void{
    this.HardCodedReports = []
    this.HardCodedReportFiles = {}

    this.blockUI.start(`Iniciando reportes, por favor espere...`);
    this.companyService.GetHardCodedReportTypes().subscribe(next => {
      this.blockUI.stop();
      if (next.result) {
        this.HardCodedReports = next.Data;
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.SuccessAlert(`Error: ${error}`);
    });
  }

  FileBrowseHandler(Event, ReportType: number): void {
    if (Event.srcElement.files) {
      let FileExtention: string = Event.srcElement.files[0].name.split('.').pop();
      if (FileExtention.toUpperCase() === 'RPT') {
        this.HardCodedReports.forEach(HardCodedReport => {
          if (HardCodedReport.ReportType == ReportType) {
            // this.file = Event.srcElement.files;
            HardCodedReport.FileName = Event.srcElement.files[0].name;
            this.HardCodedReportFiles[ReportType] = Event.srcElement.files[0];
          }
        });
      } else {
        this.alertService.ErrorAlert(`El tipo de archivo debe ser rpt`);
      }
    }
  }

  DownLoadHardCodedReport(HardCodedReport: HardCodedReport): void {
    this.companyService.DownloadHardCodedReport(HardCodedReport.ReportType, this.companyId).subscribe(resp => {
      if (resp.result) {
        this.DowloadFile(resp.Data, HardCodedReport.FileName.split('.')[0], 'application/octet-stream', 'rpt');
      } else {
        this.alertService.ErrorAlert(`Error al descargar archivo.${resp.errorInfo.Message}`);
      }
    }, error => {
      this.alertService.ErrorAlert(`Error: ${error}`);
    });
  }

  private DowloadFile(
    base64File: string,
    fileName: string,
    blobType: string,
    fileExtension: string
  ): void {
    let report = new Blob([this.StringToArrayBuffer(atob(base64File))], {
      type: blobType,
    });

    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(report);
    link.download = `${fileName}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private StringToArrayBuffer(toConvert: string): ArrayBuffer {
    let buf = new ArrayBuffer(toConvert.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != toConvert.length; ++i)
      view[i] = toConvert.charCodeAt(i) & 0xff;
    return buf;
  }

  OpenAndLoadHardCodedReports(content): void {
    if(!this.companyId) 
    {
      this.ModalHardCodedReport = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-sm' });
      return;
    }
    this.blockUI.start(`Cargando reportes, por favor espere`);
    this.companyService.GetHardCodedReports(this.companyId).subscribe(next => {
      this.blockUI.stop();
      if (next.result) {
        this.HardCodedReports.forEach(LocalHardCodedReport => {
          next.Data.forEach(HardCodedReport => {
            if(LocalHardCodedReport.ReportType == HardCodedReport.ReportType){
              LocalHardCodedReport.FileName = HardCodedReport.FileName;
            }
          })
        })
      }
      this.ModalHardCodedReport = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-sm' });
    }, error => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`Error: ${error}`);
    });
  }

  SaveHardCodedReports(): void {
    let frmdata = new FormData();
    frmdata.append('hardCodedReports', JSON.stringify(this.HardCodedReports));

    this.HardCodedReports.forEach(Report => {
      if(this.HardCodedReportFiles[Report.ReportType]){
        frmdata.append('file_'+Report.ReportType, this.HardCodedReportFiles[Report.ReportType]);
      }
    })

    this.companyService.UpdateHardCodedReports(frmdata, this.companyId,this.alertService);
  }

  CloseHardCodedReports(): void {
    this.HardCodedReports.forEach(hcr => hcr.FileName = '');
    this.HardCodedReportFiles = [];
    this.ModalHardCodedReport.close();
  }

  // Fin Hard Coded Reports

  // Inicio configuracion de eventos

  ResetLogSettingsForm(): void {
    this.LogSettingsForm = this.fb.group({
      SyncInfo: [false, [Validators.required]],
      SyncSuccess: [false, [Validators.required]],
      SyncWarning: [false, [Validators.required]],
      SyncError: [false, [Validators.required]],
    });
  }

  // Fin configuracion de eventos

  LoadSettings(){
    this.blockUI.start("Cargando la configuración de eventos...")
    this.companyService.GetSettings().subscribe(req => {
      this.blockUI.stop();
      if(req.result){
        this.settings = req.Data;
        this.LoadServiceLayerConfig();
      } else {
        this.alertService.ErrorAlert(`Error: ${req.errorInfo.Message}`);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`Error: ${error}`);
    });
  }
  ResetSchedulingSettingsForm():void{
    this.SchedulingSettingsForm = this.fb.group({
      GoogleServiceAccount: ["", [Validators.required]],
      GoogleServiceAccountPrivateKey: ["",[Validators.required]],
      OutlookServiceUrl:["", [Validators.required]]
    });
  }


  SaveServiceLayerSettings(): void{
    let setting: ISetting = this.settings.find(x => x.Code === "useServiceLayer");

    let keyValue: SettingKeyValue<boolean> = { Key: "useServiceLayer", Value: this.useServiceLayer.value };

    if(setting)
    {
      setting.Json = JSON.stringify(keyValue);
    }
    else
    {
      setting = {
        Id: -1,
        Code: 'useServiceLayer',
        View: 'Company',
        Json: JSON.stringify(keyValue)
      }
    }

    this.companyService.StoreSetting(setting).subscribe(req => {
      if(!req.result)
      {
        this.alertService.ErrorAlert(`Error: ${req.errorInfo.Message}`);
      }
    }, error => {

      this.alertService.ErrorAlert(`Error: ${error}`);

    });
  }

  LoadServiceLayerConfig(): void{
    this.useServiceLayer = new FormControl();

    let setting: ISetting = this.settings.find(x => x.Code === "useServiceLayer");

    if(setting)
    {
      let keyValue: SettingKeyValue<boolean> = JSON.parse(setting.Json);

      this.useServiceLayer.setValue(keyValue.Value);
    }
    else
    {
      this.useServiceLayer.setValue(false);
    }
  }

  CustomMethodOpenInvSetting(_jsonData: string): void
  {
    let DocsToValidate: string[] = JSON.parse(_jsonData);

    for (const control in this.ValidateInvSettingForm.controls) {
      if(DocsToValidate.some(doc => doc == control))
      {
        this.ValidateInvSettingForm.controls[control].setValue(true);
      }
    }
  }

  CustomMethodSaveInvSetting(_jsonData?: string): string
  {
    let DocsToValidate: string[] =[];

    for (const control in this.ValidateInvSettingForm.controls) {
      if(this.ValidateInvSettingForm.controls[control].value)
      {
        DocsToValidate.push(control);
      }
    }

    return JSON.stringify(DocsToValidate);
  }

  CustomMethodOpenReportMangerSetting(_jsonData: string): void
  {
    this.reportManagerSettings = JSON.parse(_jsonData) as IReportManagerSetting[];

    let setting: IReportManagerSetting = this.reportManagerSettings.find(rs => rs.CompanyId === this.companyId);

    if(setting)
    {
      this.RptMngSettingForm.patchValue(setting);
    }
  }

  CustomMethodSaveRptMngSetting(_jsonData: string): string
  {
    let settingRpt: IReportManagerSetting = JSON.parse(_jsonData) as IReportManagerSetting;

    let settingIndex: number = this.reportManagerSettings.findIndex(s => s.CompanyId === this.companyId);

    if(settingIndex !== -1)
    {
      this.reportManagerSettings.splice(settingIndex, 1, settingRpt);
    }
    else
    {
      this.reportManagerSettings.push(settingRpt);
    }

    return JSON.stringify(this.reportManagerSettings);
  }
  OpenSettingModal(_content: NgbModalRef, _settingKey: string, _customMethod?: ((_jsonData: string) => void)): void
  {
    try {
      let setting: ISetting = this.settings.find(x => x.Code == _settingKey);

      if(setting)
      {
        if(_customMethod)
        {
          _customMethod.call(this, setting.Json);
        }
        else
        {
          let formName: string = this.settingFormBySettingKey[_settingKey];
          (this[formName] as FormGroup).patchValue(JSON.parse(setting.Json));
        }
      }
    } catch (error) {
      console.error(error);
      return;
    }
    

    this.modalService.open(
      _content,
      { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-sm' }
    );
  }

  SaveSettingChanges(_content: NgbModalRef, _settingKey: string, _customMethod: ((_jsonData?: string) => string)): void
  {

    this.blockUI.start("Guardando cambios...");

    let setting: ISetting = this.settings.find(x => x.Code === _settingKey);

    let formName: string = this.settingFormBySettingKey[_settingKey];

    let formSerializedValue: string = '';

    let serializedValue: string = '';

    try
    {
      formSerializedValue = JSON.stringify((this[formName] as FormGroup).value);

      serializedValue = _customMethod ? _customMethod.call(this, formSerializedValue) : formSerializedValue;
    }
    catch(exception)
    {
      console.error(exception);
      return;
    }

    if(setting)
    {
      setting.Json = serializedValue
    }
    else
    {
      setting = {
        Id: -1,
        Code: _settingKey,
        View: 'Company',
        Json: serializedValue
      }
    }

    this.companyService.StoreSetting(setting).pipe(finalize(() => this.blockUI.stop())).subscribe({
      next: (callback: BaseResponse) => {
        if(callback.result){
          _content.dismiss();
          this.alertService.SuccessAlert('Configuración guardada exitosamente!');
        } else {
          this.alertService.ErrorAlert(`Error: ${callback.errorInfo.Message}`);
        }
      },
      error: (error: any) => {
        this.alertService.ErrorAlert(`Error: ${error}`);
      }
    });

  }

}




