import { Component, EventEmitter, Input, OnDestroy, OnInit } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";

// MODELOS
import { Globals } from "./../../../globals";
import {
  RolsModel,
  RolsResponse,
  PermsByRolResponse,
  PermissionsSelectedResponse,
  BaseResponse,
  IPermissionsSelectedModel,
} from "../../../models/index";

// RUTAS

// COMPONENTES

// SERVICIOS
import {
  RolService,
  PermissionsService,
  AlertService,
} from "./../../../services/index";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-perms-by-rol",
  templateUrl: "./perms-by-rol.component.html",
  styleUrls: ["./perms-by-rol.component.css"],
})
export class PermsByRolComponent implements OnInit, OnDestroy {
  Subscription$ = new Subscription();
  @Input() saveAction: EventEmitter<boolean>;
  @BlockUI() blockUI: NgBlockUI;

  rolesList: RolsModel[] = []; // lista con los roles
  rolesListWithoutOwner: RolsModel[] = []; // lista con los roles existentes menos el Owner

  permsList: IPermissionsSelectedModel[] = []; // lista con los permisos de la aplicacion
  permsByRolList: number[] = []; // lista con los identificadores de los permisos de un rol en especifico
  idRol: number; // numero del id del rol seleccionado
  // PERMISOS
  G_AssignRolPerms: boolean;
  G_UnassignRolPerms: boolean;
  constructor(
    public permsService: PermissionsService,
    public rolService: RolService,
    private alertService: AlertService,
    private globals: Globals
  ) {
  }
  ngOnDestroy(): void {
    this.Subscription$.unsubscribe();
  }

  ngOnInit() {
    this.G_AssignRolPerms = this.globals.perms.some(p => p.Name === 'G_AssignRolPerms');
    this.G_UnassignRolPerms = this.globals.perms.some(p => p.Name === 'G_UnassignRolPerms');
    this.permsList = [];
    this.initializePage();
    this.Subscription$.add(this.saveAction.subscribe({
      next: (callback) => {
        if (callback) {
          this.saveChange();
        }
      },
      error: (error) => {
        console.error(error);
      }
    }));
  }

  initializePage() {
    this.getRoles();
    this.GetPerms();
  }

  // metodo para obtener los roles
  getRoles() {
    this.rolesList = [];
    this.rolesListWithoutOwner = [];
    this.blockUI.start(); // Start blocking
    this.rolService.getRoles().subscribe(
      (data: RolsResponse) => {
        this.blockUI.stop(); // Stop blocking
        if (data.result) {
          this.rolesList = data.RolsList;
          this.rolesListWithoutOwner = data.RolsList.filter(
            (x) => x.Name !== "Owner"
          );
          this.getPermsByRol(data.RolsList[0].Id.toString());
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      },
      (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.ErrorAlert(`${error}`);
      }
    );
  }

  // obtiene los permisos del rol seleccionado
  getPermsByRol(idRol: string) {
    this.idRol = parseInt(idRol);
    this.blockUI.start(); // Start blocking
    this.permsService.GetPermsByRol(this.idRol)
    .pipe(finalize(()=>this.blockUI.stop()))
    .subscribe({
      next: (data: PermsByRolResponse) => {
        // llamado a funcion para colocar en false el selected de los permisos
        this.resetPermsList();
        if (data.result) 
        {
          if(data.errorInfo)
          {
            this.alertService.ToastInfoAlert(data.errorInfo.Message);
          }
          else
          {
            this.alertService.ToastSuccessAlert(`Proceso finalizado exitosamente`);
            this.permsByRolList = data.PermsByRolList;
            this.permsList.filter((perm) =>
            this.permsByRolList.forEach((element) => {
              if (perm.Id === element) {
                perm.Selected = true;
              }
            }));
          }
        } 
        else 
        {
          this.permsByRolList = [];
          this.alertService.ErrorAlert(data.errorInfo.Message);
        }
      },
      error: (error) => {
        this.alertService.ErrorAlert(error);
      }
    });
  }
  // funcion para agregar todos los permisos al rol
  addAllPerms() {

    if (!this.G_AssignRolPerms) {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    this.permsList.forEach((perm) => {
      perm.Selected = true;
    });
  }

  AssignPerm(_perm: IPermissionsSelectedModel): void {
    if (!this.G_AssignRolPerms) {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }
    _perm.Selected = true;
  }

  // funcion para remover todos los permisos al rol
  removeAllPerms() {
    if (!this.G_UnassignRolPerms) {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }
    
    this.permsList.forEach((perm) => {
      perm.Selected = false;
    });
  }
  RemovePerm(_perm: IPermissionsSelectedModel): void {
    if (!this.G_UnassignRolPerms) {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    _perm.Selected = false;
  }

  // obtiene los permisos existentes
  GetPerms() {
    this.blockUI.start(); // Start blocking
    this.permsService.GetPerms()
    .pipe(finalize(()=>this.blockUI.stop()))
    .subscribe(
      (data: PermissionsSelectedResponse) => {
        if (data.result) 
        {
          if(data.errorInfo)
          {
            this.alertService.ToastInfoAlert(data.errorInfo.Message);
          }
          else
          {
            this.permsList = data.PermissionsList;
          }
        } 
        else 
        {
          this.permsList = [];
          this.alertService.ErrorAlert(data.errorInfo.Message);
        }
      },
      (error) => {
        this.alertService.ErrorAlert(error);
      }
    );
  }

  // funcion para resetear la propiedad selected de los permisos en false
  resetPermsList() {
    this.permsList.filter((perm) => {
      perm.Selected = false;
    });
  }

  saveChange() {
    if (!this.G_AssignRolPerms && !this.G_UnassignRolPerms) {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }
    this.blockUI.start(); // Start blocking
    let permByRolList: any[] = [];
    this.permsList.forEach((perm) => {
      if (perm.Selected) {
        permByRolList.push({
          Id: 0,
          PermId: perm.Id,
          RolId: this.idRol,
          Active: true,
        });
      }
    });

    let permsRolModel = {
      permByRolList: permByRolList,
      idRol: this.idRol,
    };
    this.permsService.savePermsByRol(permsRolModel).subscribe(
      (data: BaseResponse) => {
        this.blockUI.stop(); // Stop blocking
        if (data.result) {
          this.alertService.SuccessAlert(`Proceso finalizado exitosamente`);
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      },
      (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.ErrorAlert(`${error}`);
      }
    );
  }
}
