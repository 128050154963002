import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FrequencyService, AlertService } from '../../../services/index';
import { Router } from '@angular/router';
import { FrequencyListResponse, BaseResponse } from './../../../models/index';
import { Frequencies } from 'src/app/models/constantes';

@Component({
  selector: 'app-frequency-conf',
  templateUrl: './frequency-conf.component.html',
  styleUrls: ['./frequency-conf.component.css']
})
export class FrequencyConfComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  InEdition: boolean;
  freqId: string; // Id de la frecuencia en caso de ser editar.
  title: string; // titulo
  freqGroup: FormGroup; // nombre dle formulario
  btnSendInfo: string; // cambia el titulo de el boton de envio
  disable: boolean; // si algunas variables no se pueden modificar
  frequncy: any; // modelo de frecuencia por si se quiere editar
  userList: any[] = []; // lista para los 
  routeFrequencies: any[];

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private fbg: FormBuilder,
    private router: Router,
    private freqService: FrequencyService) {
  }

  ngOnInit() {
    this.initializePage();
  }

  // obtiene los errores de los formularios
  get f() { return this.freqGroup.controls; }

  initializePage() {
    this.freqId = this.activatedRoute.snapshot.paramMap.get('Id').toString();
    if (this.freqId === '0') {
      this.title = 'Creación de frecuencia';
    } else {
      this.title = 'Edición de frecuencia';
    }
    this.freqGroup = this.fbg.group({
      comb: [''],
      Description: ['', [Validators.required]],
      Active: [''],
      AllDays: [''],
      Monday: [''],
      Tuesday: [''],
      Wednesday: [''],
      Thursday: [''],
      Friday: [''],
      Saturday: [''],
      Sunday: ['']
    });
    this.routeFrequencies = Frequencies;

    if (this.freqId !== '0') {
      this.disable = true;
      this.btnSendInfo = 'Guardar';
      this.InEdition = true;
      this.chargeFreq();
    } else {
      this.btnSendInfo = 'Crear';
      this.InEdition = false;
    }
    this.freqGroup.patchValue({ comb: 0 });
  }

  // carga la frecuancia que se va a modificar
  chargeFreq() {
    this.blockUI.start();
    this.freqService.GetFrequencyList()
      .subscribe((data: FrequencyListResponse) => {
        this.blockUI.stop();
        if (data.result) {
          data.frequencyList.forEach(element => {
            if (element.Id.toString() === this.freqId) {
              element.Days.split('.').forEach(number => {
                this.chargeDays(number);
              });
              this.freqGroup.patchValue({
                comb: element.Mix,
                Description: element.Description,
                Active: element.Active
              });
              this.MarkAllOption();
            }
          });
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  // al cargar la frecuancia que se va a editar llama a esta funcion para activar los cheks de los dia
  // que estan registrados
  chargeDays(strday: string) {
    switch (strday) {
      case '2':
        this.freqGroup.controls.Monday.setValue(true);
        break;
      case '3':
        this.freqGroup.controls.Tuesday.setValue(true);
        break;
      case '4':
        this.freqGroup.controls.Wednesday.setValue(true);
        break;
      case '5':
        this.freqGroup.controls.Thursday.setValue(true);
        break;
      case '6':
        this.freqGroup.controls.Friday.setValue(true);
        break;
      case '7':
        this.freqGroup.controls.Saturday.setValue(true);
        break;
      case '1':
        this.freqGroup.controls.Sunday.setValue(true);
        break;
    }
  }

  // marca el cehk de todos si todos estan marcados
  MarkAllOption() {
    if (this.freqGroup.controls.Monday.value &&
      this.freqGroup.controls.Tuesday.value &&
      this.freqGroup.controls.Wednesday.value &&
      this.freqGroup.controls.Thursday.value &&
      this.freqGroup.controls.Friday.value &&
      this.freqGroup.controls.Saturday.value &&
      this.freqGroup.controls.Sunday.value) {
      this.freqGroup.controls.AllDays.setValue(true);
    } else {
      this.freqGroup.controls.AllDays.setValue(false);
    }
  }

  // marca y desmarca todos los check box
  MarkAll(e) {
    let boolVal = e.target.checked;
    this.freqGroup.controls.Monday.setValue(boolVal);
    this.freqGroup.controls.Tuesday.setValue(boolVal);
    this.freqGroup.controls.Wednesday.setValue(boolVal);
    this.freqGroup.controls.Thursday.setValue(boolVal);
    this.freqGroup.controls.Friday.setValue(boolVal);
    this.freqGroup.controls.Saturday.setValue(boolVal);
    this.freqGroup.controls.Sunday.setValue(boolVal);
  }

  // envia la informacion del formulario de las frecuencias de las rutas
  onSubmitCreate() {
    let firstOpc = false;
    let days = '';

    if (this.freqGroup.controls.Monday.value) {
      if (!firstOpc) { days += '2'; firstOpc = true; }
    }
    if (this.freqGroup.controls.Tuesday.value) {
      if (!firstOpc) { days += '3'; firstOpc = true; } else { days += '.3'; }
    }
    if (this.freqGroup.controls.Wednesday.value) {
      if (!firstOpc) { days += '4'; firstOpc = true; } else { days += '.4'; }
    }
    if (this.freqGroup.controls.Thursday.value) {
      if (!firstOpc) { days += '5'; firstOpc = true; } else { days += '.5'; }
    }
    if (this.freqGroup.controls.Friday.value) {
      if (!firstOpc) { days += '6'; firstOpc = true; } else { days += '.6'; }
    }
    if (this.freqGroup.controls.Saturday.value) {
      if (!firstOpc) { days += '7'; firstOpc = true; } else { days += '.7'; }
    }
    if (this.freqGroup.controls.Sunday.value) {
      if (!firstOpc) { days += '1'; firstOpc = true; } else { days += '.1'; }
    }

    if (days !== '') {
      this.blockUI.start();
      this.freqService.sendFreqInfo(this.freqGroup, parseInt(this.freqId), days)
        .subscribe((data: BaseResponse) => {
          this.blockUI.stop();
          if (data.result) {
            this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
            this.router.navigate(['frequency']);
          } else {
            this.alertService.WarningAlert(`${data.errorInfo.Message}`);
          }
        }, error => {
          this.blockUI.stop();
          this.alertService.ErrorAlert(`${error}`);
        });
    } else {
      this.alertService.WarningAlert('Debe seleccionar al menos un día');
    }
  }

}
