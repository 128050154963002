import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import { AppConstants } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { WareHouseResponse } from '../models/responses';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) {
    console.log('WarehouseService listo');
  }

  // metodo que obtiene los vendedores de la aplicacion
  getWareHouses() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.get<WareHouseResponse>(`${environment.apiUrl}api/WareHouse/GetWareHouses?userMappId=${TOKEN.userMappId}&lang=es`,
      { headers }
    );
  }

}
