import { Component, OnInit, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbTypeahead, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, merge } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

// MODELOS
import {
  CardGroupsMobileModel, SubTipoModel, SubTipoResponse, CardGroupsResponse, FocusItemUIResponse, FocusItemUI,
  ItemMobileResponse, ItemsMobileModel, BaseResponse
} from './../../../models/index';
import { Globals } from './../../../globals';

// RUTAS

// COMPONENTES

// SERVICIOS
import { BussinesPartnersService, AlertService, ItemService } from './../../../services/index';

@Component({
  selector: 'app-focus-items',
  templateUrl: './focus-items.component.html',
  styleUrls: ['./focus-items.component.css']
})
export class FocusItemsComponent implements OnInit {

  // instancia del block ui
  @BlockUI() blockUI: NgBlockUI;
  // lista de canales
  channelList: CardGroupsMobileModel[] = [];
  // lista de subcanales
  subChannelList: SubTipoModel[] = [];

  Channel: number;
  SubChannel: string;

  // lista de items foco
  itemFocusList: FocusItemUI[] = [];


  // instancias del typeahead para clientes
  @ViewChild('instanceItems') instanceItems: NgbTypeahead;
  focusItems$ = new Subject<string>();
  clickItems$ = new Subject<string>();
  // Lista de items de SAP
  itemTypeaheadList: string[] = [];
  itemList: ItemsMobileModel[] = [];
  // Lista de items selecionados
  selectedItems: ItemsMobileModel[] = [];
  // selectedItems: string[] = [];
  itemSelect: string;
  title = 'Productos foco';

  /* PAGINATION CONFIG */
  totalPages: number; // cantidad total de paginas a mostrar
  page = 1; // pagina actual
  itemFocusListSplice: FocusItemUI[] = []; // lista de productos foco dividida en paginas

  // PERMISOS
  G_CreateProdFoco: boolean; // permiso para la creacion de rutas
  G_DeleteProdFoco: boolean; // permiso para la edicion de rutas

  constructor(private bpService: BussinesPartnersService,
    private alertService: AlertService,
    private itemService: ItemService,
    private globals: Globals,
  ) {
    this.G_CreateProdFoco = this.globals.perms.some(x => x.Name === 'G_CreateProdFoco');
    this.G_DeleteProdFoco = this.globals.perms.some(x => x.Name === 'G_DeleteProdFoco');
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.getChannels();
  }

  // Metodo para obtener los grupos (canales)
  getChannels() {
    this.channelList = [];
    this.subChannelList = [];
    this.Channel = 0;
    this.SubChannel = '0';
    this.blockUI.start();
    this.bpService.getChannels().subscribe((data: CardGroupsResponse) => {
      this.blockUI.stop();
      if (data && data.result) {
        this.channelList = data.cardGroupsList;
        this.getSubChannels();
      } else {
        this.alertService.WarningAlert(`${data.errorInfo.Message}`);
      }
    }, (error) => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`${error}`);
    });
  }

  // Metodo para obtener los sub tipos (sub canales)
  getSubChannels() {
    this.blockUI.start();
    this.bpService.getSubChannels().subscribe((data: SubTipoResponse) => {
      this.blockUI.stop();
      if (data && data.result) {
        this.subChannelList = data.SubTipoList;
        this.getItems();
      } else {
        this.alertService.WarningAlert(`${data.errorInfo.Message}`);
      }
    }, (error) => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`${error}`);
    });
  }

  // Metodo para obtener los items de SAP
  getItems() {
    this.blockUI.start();
    this.itemService.getItems().subscribe((data: ItemMobileResponse) => {
      this.blockUI.stop();
      if (data && data.result) {
        this.itemList = data.itemInfoList;
        this.itemTypeaheadList = data.itemInfoList.map(x => x.CompleteItemName);
      } else {
        this.alertService.WarningAlert(`${data.errorInfo.Message}`);
      }
    }, (error) => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`${error}`);
    });
  }

  // funcion para el typeahead de BP
  searchItems = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickItems$.pipe(filter(() => !this.instanceItems.isPopupOpen()));
    const inputFocus$ = this.focusItems$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.itemTypeaheadList
        : this.itemTypeaheadList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  // funcion para selecionar un item para agregarlo a productos foco
  createItemFoco() {
    // Obtenemos el codigo del item selacionado en el typeahead  
    let code = this.itemSelect.split(' - ')[0];

    // Obtenemos el item
    let itm = this.itemList.filter(
      itm => itm.ItemCode.toUpperCase() === code.toUpperCase()
    );

    // Obtenemos el grupo (canal)
    let grp = this.channelList.filter(
      grp => grp.GroupCode.toString() === this.Channel.toString()
    );

    // Obtenemos el subtipo (subcanal)
    let st = this.subChannelList.filter(
      st => st.Code === this.SubChannel
    );

    // Limpiamos la seleccion
    this.itemSelect = '';

    // Se activa el block ui
    this.blockUI.start();
    // Se crea el item para enviar a crear un foco
    let itemFocus: FocusItemUI = {
      GroupCode: grp[0].GroupCode,
      GroupName: grp[0].GroupName,
      IdFocusDetail: 0,
      IdItem: 0,
      ItemCode: itm[0].ItemCode,
      ItemName: itm[0].ItemName,
      SubTipo: st[0].Code
    };

    // Se hace el llamado al request para la creacion del item foco
    this.itemService.createItemFocus(itemFocus)
      .subscribe((data: BaseResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.alertService.SuccessAlert(`Proceso finalizado exitosamente`);
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
          console.log(data);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(`${error}`);
        console.error(error);
      });
  }

  // Metodo para obtener los items foco de la DB
  getFocusItems() {
    this.itemFocusList.length = 0;
    this.blockUI.start();
    this.itemService.getFocusItems(this.Channel, this.SubChannel).subscribe((data: FocusItemUIResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.itemFocusList = data.itemFocusList;
          this.totalPages = Math.ceil(data.itemFocusList.length / 10) * 10;
          this.page = 1;
          this.pageChange();
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
          console.log(data);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(`${error}`);
        console.error(error);
      });
  }


  // Metodo para eliminar un prod foco
  deleteItemFocus(itemFocus: FocusItemUI) {
    this.itemService.deleteItemFocus(itemFocus).subscribe((data: BaseResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.alertService.SuccessAlert(`Proceso finalizado exitosamente`);
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
          console.log(data);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(`${error}`);
        console.error(error);
      });
  }

  // Metodo para cambiar el valor por defecto en los dropdowns de los tabs, segun el tab activo
  public beforeChange($event: NgbTabChangeEvent) {
    if ($event.nextId === 'tab-list') {
      this.Channel = 0;
      this.SubChannel = '0';
    } else {
      this.Channel = (this.channelList.length)? this.channelList[0].GroupCode : 0;
      this.SubChannel = (this.subChannelList.length)? this.subChannelList[0].Code : '0';

    }
  }

  // funcion para obtener la nueva lista de productos foco para mostrar en el front
  pageChange() {
    this.itemFocusListSplice = this.itemFocusList.slice((this.page - 1) * 10, this.page * 10);
  }

}
