import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';

// MODELOS
import { Globals } from './../../../globals';
import { FrequencyListResponse, Week } from './../../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { AlertService, FrequencyService } from './../../../services/index';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-frequency-view',
  templateUrl: './frequency-view.component.html',
  styleUrls: ['./frequency-view.component.css']
})
export class FrequencyViewComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  frequencyList: any[] = [];
  title = 'Frecuencias';

  // PERMISOS
  G_CreateFrequency: boolean; // permiso para la creacion de rutas
  G_EditFrequency: boolean; // permiso para la edicion de rutas

  constructor(private alertService: AlertService,
    private router: Router,
    private freqService: FrequencyService,
    private globals: Globals,
  ) {
    this.G_CreateFrequency = this.globals.perms.some(x => x.Name === 'G_CreateFrequency');
    this.G_EditFrequency = this.globals.perms.some(x => x.Name === 'G_EditFrequency');
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.ChargeFreqList();
  }

  // carga la lista de frecuansias que estan registradas en BD
  ChargeFreqList(): void {
    this.blockUI.start();
    
    this.freqService.GetFrequencyList().pipe(finalize(() => this.blockUI.stop())).subscribe((data: FrequencyListResponse) => {
      if (data.result) 
      {
        if(data.errorInfo)
        {
          this.alertService.InfoAlert(data.errorInfo.Message);
        }
        else
        {
          this.frequencyList = data.frequencyList;
        }
      } 
      else 
      {
        this.alertService.ErrorAlert(`${data.errorInfo.Message}`);
      }
    }, error => {
      console.error(error);
      this.alertService.ErrorAlert(error);
    });
  }

  // envia a la pagina de configuracion a crear o a editar
  confPage(id) {
    this.router.navigate(['frequencyConf/' + id]);
  }

  getDaysName(daysValues: string) {
    let daysValuesArray = daysValues.split('.');
    let daysNames: string = '';

    daysValuesArray.forEach((day, index) => {
      let dayObject = Week.find(x => x.Value.toString() == day);

      if (dayObject) daysNames += `${dayObject.Name}`;
      if (index != daysValuesArray.length - 1) {
        daysNames += ', ';
      }
    });

    return daysNames;
  }
}
