import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import { AppConstants, GetBPResponse, CardGroupsResponse, SubTipoResponse, BusinessPartnersMobileResponse, BusinessPartnersModel, Location } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { ApiResponse, BaseResponse, LocationsResponse } from '../models/responses';
import { environment } from 'src/environments/environment';
import { BusinessPartnerMinified } from '../models/bussinesPartners';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BussinesPartnersService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  // metodo que obtiene los clientes de la compannia
  GetAllCustomers(Balance: boolean) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<GetBPResponse>(`${environment.apiUrl}api/BusinessPartners/GetAllCustomers?userMappId=${TOKEN.userMappId}&Balance=${Balance}`,
      { headers }
    );

  }

  /**
   * Obtiene un socio de negocios por medio de su codigo de socio
   * @param _cardCode Codigo de socio de negocios que se quiere obtener informacion
   * @returns Un socio de negocios
   */
   GetCustomer(_cardCode: string): Observable<ApiResponse<BusinessPartnersModel>>
   {
     const URL = `${environment.apiUrl}api/BusinessPartners/GetCustomer?cardCode=${_cardCode}`;
     return this.http.get<ApiResponse<BusinessPartnersModel>>(URL);
   }

   /**
    * Obtiene solamente el CardCode y CardName de los clientes
    * @returns Lista de clientes minificados
    */
  GetMinifiedCustomer(): Observable<ApiResponse<BusinessPartnerMinified[]>>
  {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<ApiResponse<BusinessPartnerMinified[]>>(`${environment.apiUrl}api/BusinessPartners/GetMinifiedCustomers?userMappId=${TOKEN.userMappId}`, { headers })
  }

  GetCustomersToActive(fechaIni: Date, fechaFin: Date) {

    const CURRENTUSER = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${CURRENTUSER.access_token}`
    });

    return this.http.get<BusinessPartnersMobileResponse>(`${environment.apiUrl}api/BusinessPartners/GetBPforAppCreate?fechaIni=${fechaIni}&fechaFin=${fechaFin}&userMappId=${CURRENTUSER.userMappId}`,
      { headers }
    );

  }

  // metodo para obtener los canales
  getChannels() {

    let currentUser = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${currentUser.access_token}`
    });

    return this.http.get<CardGroupsResponse>(`${environment.apiUrl}api/BusinessPartners/GetGroupCode?userMappId=${currentUser.userMappId}`,
      { headers }
    );

  }

  // metodo para obtener los sub tipos (sub canales)
  getSubChannels() {

    let currentUser = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${currentUser.access_token}`
    });

    return this.http.get<SubTipoResponse>(`${environment.apiUrl}api/BusinessPartners/GetSubTipo?userMappId=${currentUser.userMappId}`,
      { headers }
    );

  }

  activateCustomer(CardCode: string) {

    const session = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${session.access_token}`
    });

    return this.http.put<BaseResponse>(`${environment.apiUrl}api/BusinessPartners/ActivateCustomer?CardCode=${CardCode}&userMappId=${session.userMappId}`,
      {}, { headers }
    );

  }

  updateCoordsSAP(location: Location, cardCode: string) {

    const CURRENTUSER = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${CURRENTUSER.access_token}`
    });

    return this.http.post<BaseResponse>(`${environment.apiUrl}api/BusinessPartners/UpdateCoordsSAP?cardCode=${cardCode}&userMapId=${CURRENTUSER.userMappId}`,
      location,
      { headers }
    );

  }

  getCustomerLocations(CardCode: string) {

    const CURRENTUSER = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${CURRENTUSER.access_token}`
    });

    return this.http.get(`${environment.apiUrl}api/BusinessPartners/GetLocations?CardCode=${CardCode}&userMappId=${CURRENTUSER.userMappId}&lang=es`, { headers });
  }
}
