import { Pipe, PipeTransform } from "@angular/core";
import { DocumentSubtypes } from "../models";

@Pipe({
  name: "documentSubtype",
})
export class DocumentSubtypePipe implements PipeTransform {
  transform(documentSubtypes: any[], docType: string): any {
    return documentSubtypes.filter((x) =>
      x.Parents.some((y: any) => y === docType)
    );
  }
}
