import { Component, EventEmitter, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// MODELOS
import { UsersResponse, UsersModel, RolsResponse, RolsModel, UserRouteModel, RolUserResponse, RolUserModel, BaseResponse } from '../../../models/index';
import { Globals } from './../../../globals';

// RUTAS

// COMPONENTES

// SERVICIOS
import { RolService, UserService, AlertService } from './../../../services/index';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-rol-by-user',
  templateUrl: './rol-by-user.component.html',
  styleUrls: ['./rol-by-user.component.css']
})
export class RolByUserComponent implements OnInit, OnDestroy {

  @ViewChild('contentRolUser') contentRolUser: any;
  @BlockUI() blockUI: NgBlockUI;
  @Input() createAction: EventEmitter<boolean>;
  title = 'Asignación rol por usuarios';
  userList: UserRouteModel[] = []; // lista de usuarios
  rolesList: RolsModel[] = []; // lista con los roles existentes
  rolesListWithoutOwner: RolsModel[] = []; // lista con los roles existentes menos el Owner
  rolId: number; // id del rol seleccionado
  rolAssignList: RolUserModel[] = []; // lista con las asignaciones de rol por usuario y compañia
  userMappId: number; // id de la asigacion de usuario
  usersList: UserRouteModel[] = []; // lista completa de los usuarios del owner de la cuenta

  // ***VARIABLES DE LA MODAL DE ASIGNACION DE ROL POR USUARIO Y COMPAÑIA*** //
  IsEdition: boolean;
  rolUserForm: FormGroup; // variable del formulario de rol por usuario y compañía
  action: string; // accion del boton de crear o modificar la asignacion de rol por usuario y compañia
  modalReference: any; // instancia de la modal de creacion o edicion de la asignacion de rol por usuario y compañia
  closeResult: string; // resultado de cerrar la modal
  idAssign: number; // variable para reconocer si esta creando o modificando una la asignacion de rol por usuario y compañia, 0 = create, 0 != update

  // ***VARIABLES PARA ORDENAMIENTO DE LA TABLA*** //
  path: string[] = ['rolAsgn'];
  order = 1; // 1 asc, -1 desc;

  // PERMISOS
  G_CreateRolByUserAssign: boolean; // permiso para la creacion de rutas
  G_EditRolByUserAssign: boolean; // permiso para la edicion de rutas
  G_UnassignRol: boolean;
  Subscription$ = new Subscription();
  constructor(private fb: FormBuilder,
    private userService: UserService,
    private rolService: RolService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private globals: Globals
  ) {
    
  }
  ngOnDestroy(): void {
    this.Subscription$.unsubscribe();
  }

  ngOnInit() {
    this.initializePage();
    this.G_CreateRolByUserAssign = this.globals.perms.some(x => x.Name === 'G_CreateRolByUserAssign');
    this.G_EditRolByUserAssign = this.globals.perms.some(x => x.Name === 'G_EditRolByUserAssign');
    this.G_UnassignRol = this.globals.perms.some(x => x.Name === 'G_UnassignRol');
  }

  initializePage() {
    this.getRoles();
    this.setForms();
    this.Subscription$.add(this.createAction.subscribe({
      next: (callback) => {
        if (callback)
        {
          this.open(null, this.contentRolUser)
        }
      }
    }));
  }

  // convenience getter for easy access to form fields
  get fRolUser() { return this.rolUserForm.controls; }

  getRoles() {
    this.rolesList.length = 0;
    this.rolesListWithoutOwner.length = 0;
    this.blockUI.start();
    this.rolService.getRoles().subscribe((data: RolsResponse) => {
      this.blockUI.stop();
      if (data.result) {
        this.rolesList = data.RolsList;
        this.rolesListWithoutOwner = data.RolsList.filter(x => x.Name !== 'Owner');
        this.rolId = 0;
        this.getUsersMobile();
      } else {
        this.alertService.WarningAlert(`${data.errorInfo.Message}`);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`${error}`);
    });
  }

  // funcion para obtener una lista de usuarios segun la compañía seleccionada
  getUsersMobile() {
    this.blockUI.start();
    this.userService.getUsersMobile().subscribe((data: UsersResponse) => {
      this.blockUI.stop();
      if (data.result) {
        this.userList = data.userList;
        this.userMappId = 0;
        this.GetRolsByUser();
      } else {
        this.alertService.WarningAlert(`${data.errorInfo.Message}`);
      }
    }, (error) => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`Error ${error}`);
      console.log(error);
    });
  }

  // funcion para obtener una lista de las asignaciones por rol y compañia
  GetRolsByUser() {
    this.blockUI.start(); // Start blocking
    this.rolService.GetRolsByUser(this.rolId, this.userMappId)
      .subscribe((data: RolUserResponse) => {
        this.blockUI.stop(); // Stop blocking
        if (data.result) {
          this.rolAssignList = data.rolUserList;
        } else {
          this.rolAssignList.length = 0;
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, error => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  // funcion para inicializar los formularios
  setForms() {
    this.rolUserForm = this.fb.group({
      rol: ['', Validators.required],
      user: ['', Validators.required]
    });
  }

  // funcion para la creacion de una asignacion de rol por usuario y compañía
  // funcion para la modificacion de una asignacion de rol por usuario y compañía
  open(rolAsgn: RolUserModel, contentRol) {

    if((!rolAsgn && !this.G_CreateRolByUserAssign) || (rolAsgn && !this.G_EditRolByUserAssign))
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    if (rolAsgn === null) {
      this.idAssign = 0;
      this.rolUserForm.patchValue({ rol: '' });
      this.rolUserForm.patchValue({ user: '' });
      this.action = 'Crear';
      this.IsEdition = false;
      this.openRolModal(contentRol);
    } else {
      this.idAssign = rolAsgn.assignId;
      this.rolUserForm.patchValue({ rol: rolAsgn.rolId });
      this.rolUserForm.patchValue({ user: rolAsgn.userMappId });
      this.action = 'Guardar';
      this.IsEdition = true;
      this.openRolModal(contentRol);
    }
  }

  openRolModal(contentRol) {
    this.modalReference = this.modalService.open(contentRol, { ariaLabelledBy: 'modal-basic-title', centered: true });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.GetRolsByUser();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.GetRolsByUser();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // funcion para cerrar la modal para la creacion o edicion de numeracion
  close() {
    this.modalReference.close();
  }

  // funcion para el envio del formulario, tanto de login como de signin
  onSubmit() {

    
    if (this.rolUserForm.invalid)
    {
      this.alertService.ToastInfoAlert("Faltan campos por completar");
      return;
    }

    // consulta a la DB para el login del user
    this.blockUI.start(); // Start blocking

    let rolUserCompany: RolUserModel = {
      'assignId': this.idAssign,
      'rolId': this.rolUserForm.value.rol,
      'userMappId': this.rolUserForm.value.user,
      'rolName': '',
      'userName': ''
    };

    this.rolService.setAssignRolByUser(rolUserCompany)
    .pipe(finalize(() => this.blockUI.stop()))
      .subscribe((data: BaseResponse) => {
        if (data.result) 
        {
          if(data.errorInfo)
          {
            this.alertService.ToastInfoAlert(data.errorInfo.Message);
          }
          else
          {
            this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
            this.GetRolsByUser();
            this.close();
          }
        } 
        else 
        {
          this.alertService.ErrorAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  sortTable(prop: string) {
    this.path = prop.split('.');
    this.order = this.order * (-1); // change order
    return false; // do not reload
  }

  UnassignRolByUser(_rol: RolUserModel): void
  {
    if(!this.G_UnassignRol)
    {
      this.alertService.ToastErrorAlert("No tienes permisos para realizar esta acción");
      return;
    }

    this.blockUI.start("");

    this.rolService.UnassignRolByUser(_rol)
    .pipe(finalize(() => this.blockUI.stop()))
    .subscribe({
      next: (callback) => {
        if(callback.result)
        {
          if(callback.errorInfo)
          {
            this.alertService.ToastInfoAlert(callback.errorInfo.Message);
          }
          else
          {
            this.alertService.ToastSuccessAlert('Se eliminó la asignación de rol correctamente');
            this.GetRolsByUser();
          }
        }
        else
        {
          this.alertService.ToastErrorAlert(callback.errorInfo.Message);
        }
      },
      error: (error) => {
        this.alertService.ToastErrorAlert(error);
      }
    });
  }
}
