import { Component, OnInit } from "@angular/core";

import { StorageService } from "./../../../services/storage.service";
import { Globals } from "src/app/globals";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { DeviceDetectorService } from "ngx-device-detector";
import { LogoCompanyResponse } from "src/app/models";
import { AlertService, CompanyService } from "src/app/services";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  currentUser: any; // datos de sesion
  sidebarOpened = false;
  currentSection: string = "Inicio";
  logoPath: string;
  userMappId: number;

  constructor(
    private router: Router,
    public deviceDetector: DeviceDetectorService,
    private storage: StorageService,
    private globals: Globals,
    private companyService: CompanyService,
    private alertService: AlertService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.defineTitleBasedOnURL(event.url);
      });

    this.globals.sidebarToggler.subscribe((x) => (this.sidebarOpened = x));
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.currentUser = JSON.parse(this.storage.getCurrentSession());
    this.userMappId = parseInt(this.currentUser.userMappId);
    this.getCompanyLogo();
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
    this.globals.sidebarToggler.next(this.sidebarOpened);
  }


  getCompanyLogo() {
    this.companyService.GetCompanyLogo(this.userMappId)
      .subscribe((data: LogoCompanyResponse) => {
        if (data && data.result) {
          this.logoPath = data.LogoB64;
        }
      }, (error: any) => {
        this.alertService.ErrorAlert(`${error.Message}`);
      });
  }

  defineTitleBasedOnURL(URL: string) {
    let splitURL = URL.split("/");

    switch (splitURL[1]) {
      case "":
      case "home":
        this.currentSection = "Inicio";
        break;
      case "listPromotions":
        this.currentSection = "Promociones";
        break;
      case "createRoute":
        this.currentSection = "Creación de rutas";
        break;
      case "listRoute":
        this.currentSection = "Rutas";
        break;
      case "UserAdd":
        this.currentSection = "Usuarios";
        break;
      case "userConf":
        this.currentSection = "Mantenimiento de usuarios";
        break;
      case "userAssings":
        this.currentSection = "Asignación de usuarios";
        break;
      case "userAssingsconf":
        this.currentSection = "Mantenimiento de asignaciones";
        break;
      case "frequency":
        this.currentSection = "Frecuencias";
        break;
      case "frequencyConf":
        this.currentSection = "Mantenimiento de frecuencias";
        break;
      case "companies":
        this.currentSection = "Compañías";
        break;
      case "UDFs":
        this.currentSection = "UDFs";
        break;
      case "permsByRol":
        this.currentSection = "Permisos";
        break;
      case "rolByUser":
        this.currentSection = "Asignación de roles";
        break;
      case "BP":
        this.currentSection = "Activación de clientes";
        break;
      case "focusItems":
        this.currentSection = "Productos foco";
        break;
      case "rols":
        this.currentSection = "Roles";
        break;
      case "geoRols":
        this.currentSection = "Roles geo";
        break;
      case "series":
        this.currentSection = "Series de numeración";
        break;
      case "reports":
        this.currentSection = "Administración de reportes";
        break;
      case "print-report":
        this.currentSection = "Imprimir reporte";
        break;
      case "stashed-documents":
        this.currentSection = "Documentos";
        break;
      case "event-viewer":
        this.currentSection = "Visor de eventos";
        break;
      default:
        this.currentSection = "Inicio";
        break;
    }
  }
}
