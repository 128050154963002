import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AngularImageViewerModule } from 'angular-x-image-viewer';
import { SidebarModule } from 'ng-sidebar';

// MODELOS
import { Globals } from './globals';

// RUTAS

// COMPONENTES
import { HomeComponent } from './components/home/home.component';
import { CreateRouteComponent } from './components/route/create-route/create-route.component';
import { LoginComponent } from './components/login/login.component';
import { UsersConfComponent } from './components/users/users-conf/users-conf.component';
import { UsersViewComponent } from './components/users/users-view/users-view.component';
import { UsersAssingsComponent } from './components/users/users-assings/users-assings.component';
import { UsersAssingsConfigComponent } from './components/users/users-assings-config/users-assings-config.component';
import { FrequencyViewComponent } from './components/frequency/frequency-view/frequency-view.component';
import { FrequencyConfComponent } from './components/frequency/frequency-conf/frequency-conf.component';
import { ListRouteComponent } from './components/route/list-route/list-route.component';
import { CompanyComponent } from './components/company/company.component';
import { PermsByRolComponent } from './components/permissions/perms-by-rol/perms-by-rol.component';
import { RolByUserComponent } from './components/rol/rol-by-user/rol-by-user.component';
import { BPViewComponent } from './components/bpview/bpview.component';
import { RecoverEmailComponent } from './components/recover-email/recover-email.component';
import { ListPromotionsComponent } from './components/promotions/list-promotions/list-promotions.component';
import { FocusItemsComponent } from './components/item/focus-items/focus-items.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { RolsComponent } from './components/rol/rols/rols.component';
import { UdfsViewComponent } from './components/udf/udfs.component';
import { GeoRolsComponent } from './components/geo-rols/geo-rols.component';
import { PermissionsCrudComponent } from './components/permissions/permissions-crud/permissions-crud.component';
import { GeoPermissionsComponent } from './components/geo-permissions/geo-permissions.component';
import { HerachiesComponent } from './components/company/herachies/herachies.component';

// SERVICIOS
import { AuthGuard } from './services/auth.guard';
import { AuthenticationService } from './services/authentication.service';
import { ErrorInterceptor } from './services/error.interceptor';

// PIPES
import { SortingPipe } from './pipes/sorting.pipe';
import { DecimalPipe } from '@angular/common';
import { CapitalizadoPipe } from './pipes/capitalizado.pipe';
import { PasswordPipe } from './pipes/password.pipe';
import { NumberingSeriesComponent } from './components/numbering-series/numbering-series.component';
import { DocumentSubtypePipe } from './pipes/document-subtype.pipe';
import { LocationPipe } from './pipes/location.pipe';
import { PrintReportComponent } from './components/rpt-manager/print-report/print-report.component';
import { StashedDocumentsComponent } from './components/stashed-documents/stashed-documents.component';
import { EventViewerComponent } from './components/event-viewer/event-viewer.component';
import { ProgressComponent } from './components/progress/progress.component';
import { RequestInterceptor } from './services/request-interceptor';
import { FormatHourPipe } from './pipes/formathour.pipe';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CreateRouteComponent,
    LoginComponent,
    UsersViewComponent,
    UsersConfComponent,
    UsersAssingsComponent,
    UsersAssingsConfigComponent,
    FrequencyViewComponent,
    FrequencyConfComponent,
    CapitalizadoPipe,
    PasswordPipe,
    ListRouteComponent,
    CompanyComponent,
    PermsByRolComponent,
    RolByUserComponent,
    SortingPipe,
    BPViewComponent,
    RecoverEmailComponent,
    ListPromotionsComponent,
    FocusItemsComponent,
    HeaderComponent,
    RolsComponent,
    UdfsViewComponent,
    GeoRolsComponent,
    PermissionsCrudComponent,
    GeoPermissionsComponent,
    HerachiesComponent,
    NumberingSeriesComponent,
    DocumentSubtypePipe,
    LocationPipe,
    PrintReportComponent,
    StashedDocumentsComponent,
    EventViewerComponent,
    ProgressComponent,
    FormatHourPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule.forRoot({ delayStop: 500 }),
    AgmCoreModule.forRoot({ apiKey: 'Not-necesary-here' }), // Revisar servicio GoogleLoadScriptService
    DeviceDetectorModule.forRoot(),
    SidebarModule.forRoot(),
    AngularFontAwesomeModule,
    NgbModule,
    HttpClientModule,
    AngularImageViewerModule,
    DragDropModule
  ],
  providers: [
    Globals, 
    AuthenticationService, 
    AuthGuard, 
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, 
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }