import { Injectable } from '@angular/core';
import { CompanyService } from './company.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleLoadScriptService {
  // Se debe generar un endpoint y definir donde sera guardado el key para cargarlo de forma dinamica
  key: string;

  constructor(
    private companyService: CompanyService,
    private alertService: AlertService
  ) { 
    this.key = '';
  }

  // Load google maps script
  LoadScript(): void{
    if(document.getElementById("GoogleScript")){
      console.log('Google maps script is already loaded');
    } else {
      this.companyService.GetSettingByCode("GoogleApiKey").subscribe(req => {
        if(req.result){
          this.key = JSON.parse(req.Data.Json);
        }
        if(this.key){
          let googleMapsScript = document.createElement("script");
          googleMapsScript.id = "GoogleScript";
          googleMapsScript.type = "text/javascript";
          googleMapsScript.async = true;
          googleMapsScript.defer = true;
          googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${this.key}`;
          document.body.appendChild(googleMapsScript);
        } else {
          this.alertService.ErrorAlert((req.result)?'Google maps key vacia':req.errorInfo.Message);
        }
      }, error => {
        this.alertService.ErrorAlert(JSON.stringify(error));
      });
    }
  }
}
