import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService, AlertService } from '../../../services/index';
import { Router } from '@angular/router';
import { BaseResponse, UserRouteModel, UsersModel, UsersResponse } from './../../../models/index';
import { UserEmailDomain } from 'src/app/enums/enums';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-users-conf',
  templateUrl: './users-conf.component.html',
  styleUrls: ['./users-conf.component.css']
})

export class UsersConfComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private fbg: FormBuilder,
    private router: Router,
    private uService: UserService) { }

  userGroup: FormGroup;
  userId: string;
  btnSendInfo: string; // cambia el titulo de el boton de envio
  disable: boolean;
  user: UserRouteModel;
  title: string;
  isEditing: boolean;

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.userGroup = this.fbg.group({
      UserName: ['', [Validators.required]],
      userPass: ['', [Validators.required]],
      userEmail: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      userPassEmail: [''],
      Active: [''],
      Scheduling: [false],
      EmailType: ["1", Validators.required]
    });

    this.userId = this.activatedRoute.snapshot.paramMap.get('userId').toString();

    if (this.userId === '0') {
      this.title = 'Creación de usuario';
    } else {
      this.title = 'Edición de usuario';
    }
    this.disable = false;
    if (this.userId !== '0') 
    {
      this.disable = true;
      this.isEditing = true;
      this.btnSendInfo = 'Guardar';
      this.chargeUser();
      this.userGroup.controls["userPass"].clearValidators();
      this.userGroup.controls["userPassEmail"].clearValidators();
    } 
    else
    { 
      this.isEditing = false;
      this.btnSendInfo = 'Crear'; 
    }
  }

  get f() { return this.userGroup.controls; }

  onSubmitCreate() {

    if (this.userGroup.invalid)
    {
      for (const control in this.userGroup.controls) {
        this.userGroup.controls[control].markAsTouched();
      }
      return;
    }

    this.blockUI.start();

    let user: UsersModel = {
      userId: this.userId,
      userName: this.userGroup.value.UserName,
      userEmail: this.userGroup.value.userEmail,
      password: this.userGroup.value.userPass,
      Active: this.userGroup.value.Active !== "" ? this.userGroup.value.Active : false,
      Scheduling: this.userGroup.value.Scheduling !== "" ? this.userGroup.value.Scheduling : false,
      PasswordEmail: this.userGroup.value.userPassEmail,
      EmailType: this.userGroup.value.EmailType
    };

    let petition$: Observable<BaseResponse>;

    if(this.isEditing)
    {
      petition$ = this.uService.UpdateUser(user);
    }
    else
    {
      petition$ = this.uService.CreateUser(user);
    }

    petition$.pipe(finalize(() => this.blockUI.stop()))
      .subscribe((data: BaseResponse) => {
        if (data.result) 
        {
          this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
          setTimeout(() => {
            this.router.navigate(['UserAdd']);
          }, 500);
        } 
        else 
        {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        console.error(error);
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  chargeUser() {
    this.blockUI.start();
    this.uService.getUsers().pipe(finalize(() => this.blockUI.stop()))
      .subscribe((data: UsersResponse) => {
        if (data.result) 
        {
          this.user = data.userList.find(us => us.userId === this.userId);

          this.charge();
        } 
        else 
        {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  charge() {
    this.userGroup.controls["UserName"].setValue(this.user.userName);
    this.userGroup.controls["userEmail"].setValue(this.user.userEmail);
    this.userGroup.controls["Active"].setValue(this.user.Active);
    this.userGroup.controls["Scheduling"].setValue(this.user.Scheduling);
    this.userGroup.controls["EmailType"].setValue(this.user.EmailType);
  }
  UserEmailDomainIsGoogle(): Boolean {
    return Number(this.userGroup.controls["EmailType"].value) === UserEmailDomain.Gmail;
  }

  PutEmailPassAsRequired()
  { 
    let scheduling: boolean = this.userGroup.controls["Scheduling"].value;

    if(!this.isEditing && scheduling)
    {
      let emailType: number = Number(this.userGroup.controls["EmailType"].value);
      
      if(emailType === UserEmailDomain.Outlook)
      {
        this.userGroup.controls["userPassEmail"].setValidators([Validators.required]);
      }
      else
      {
        this.userGroup.controls["userPassEmail"].clearValidators();
      }
    }
    else
    {
      this.userGroup.controls["userPassEmail"].clearValidators();
    }

    this.userGroup.controls["userPassEmail"].updateValueAndValidity();
  }
}
