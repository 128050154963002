// enumeracion para el tipo de descuento
export enum DiscountPromoType {
  Discount = 1,
  Price = 2,
}

// enumeracion para la secuencia de las promociones
export enum SequencePromoType {
  Next = 1,
  AllNext = 2,
  All = 3,
}

export enum AddressType {
  BillTo = 1,
  ShipTo = 2,
}

export enum CheckType {
  CheckOutFail = 0,
  CheckOutSuccess = 1,
  CheckAuto = 2,
  CheckCancel = 3,
  CheckIn = 4,
  NoApply = 5,
  CheckOut = 6,
  DocumentCheck = 7,
}

export enum RouteStatus {
  Inactive = 1,
  Active = 2,
}

export enum RouteTypes {
  Ship = 5,
  Bill = 6,
  None = 0,
}

export enum Geoconfigs {
  MultipleChecks = 1,
  DocumentCheck = 2,
  FarFromLocationCheck = 3,
  CreateBPActive = 4,
  ShipRoutes = 5,
  BillRoutes = 6,
  AlwaysDownloadRoute = 7,
  RouteAdmin = 8,
  CreateDocumentOutOfRange = 9
}

export enum DocumentType {
  CreditInvoice = 1,
  AccountPayment = 2,
  ReserveInvoice = 3,
  SaleOrder = 4,
  CashInvoice = 5,
  IncommingPayment = 6,
  InvoicePayment = 7,
  SaleOffer = 8,
  BusinessPartner = 9,
  Delivery = 10
}

export enum TransactionType{
  BusinessPartner = "BusinessPartner",
  CreditInvoice = "CreditInvoice",
  AccountPayment = "AccountPayment",
  ReserveInvoice = "ReserveInvoice",
  SaleOrder = "SaleOrder",
  CashInvoice = "CashInvoice",
  IncommingPayment = "IncommingPayment",
  InvoicePayment = "InvoicePayment",
  SaleOffer = "SaleOffer",
  Delivery = "Delivery"
}

export enum NumberingSeries {
  Oferta = 11,
  Orden = 12,
  FacturaFE = 13,
  FacturaTE = 14,
  FacturaReservaFE = 15,
  FacturaReservaTE = 16,
  PagoRecibido = 17,
  SocioNegocios = 18,
  Entrega = 19
}

export enum UDFFieldType {
  Numeric = 1,
  Alpha,
  Select,
  Date,
  Decimal
}

export enum Discounts {
  BP = 1,
  ItemGroup,
  BPGroupXItemGroup,
  BPGroupXItem,
  BPXItemGroup,
  BPXItem,
  BPXItemFamily,
  BlanketAgreements
}

export enum REPORT_PARAMETER {
  Alpha = 1,
  Numeric,
  Date,
  Boolean,
  MultipleOption,
}

export enum PermissionType {
  TitleWeb = 1,
  MenuWeb = 2,
  SubMenuWeb = 3,
  MenuMovil = 4,
  SubMenuMovil = 5,
  GeneralMovil = 6,
  GeneralWeb = 7,
  ReportUse = 8,
}

export enum ReportType {
  FacturaCredito = 1,
  FacturaReserva = 3,
  OrdenVenta = 4,
  FacturaContado = 5,
  PagoRecibido = 6,
  PagoFactura = 7,
  OfertaVenta = 8,
  BusinessPartner = 9
}

export enum LogEvent {
  INFO,
  SUCCESS,
  WARNING,
  ERROR
}

export enum CalculationType {
  CALCULATED,
  ESTIMATED
}

export enum UserEmailDomain
{
  Outlook = 1,
  Gmail
}