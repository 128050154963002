import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { GeoRolsResponse, AppConstants, GeoConfigsResponse, GeoConfig, BaseResponse, GeoRol } from 'src/app/models';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeoRolsService {

  constructor(private http: HttpClient,
    private storage: StorageService
  ) { }

  GetGeoRols() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.get<GeoRolsResponse>(`${environment.apiUrl}api/GeoRols/GetGeoRols`, { headers });
  }

  GetGeoConfigs(geoRolId: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<GeoConfigsResponse>(`${environment.apiUrl}api/GeoRols/GetGeoConfigs?geoRolId=${geoRolId}`, { headers });
  }

  PostGeoConfigs(geoRolId: number, geoConfigs: GeoConfig[]) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<BaseResponse>(`${environment.apiUrl}api/GeoRols/PostGeoConfigs?geoRolId=${geoRolId}`, geoConfigs, { headers });
  }

  handlePostOrPutGeoRol(geoRol: GeoRol) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    if (geoRol.Id > 0) return this.http.put<BaseResponse>(`${environment.apiUrl}api/GeoRols/PutGeoRol`, geoRol, { headers });
    else return this.http.post<BaseResponse>(`${environment.apiUrl}api/GeoRols/PostGeoRol`, geoRol, { headers });
  }

  deleteGeoRol(geoRolId: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.delete<BaseResponse>(`${environment.apiUrl}api/GeoRols/DeleteGeoRol?geoRolId=${geoRolId}`, { headers });
  }

  PutGeoConfig(geoConfig: GeoConfig) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<BaseResponse>(`${environment.apiUrl}api/GeoRols/PutGeoConfigPermission`, geoConfig , { headers });
  }

  UnassignGeoRol(_userMappId: number): Observable<BaseResponse>
  {
    return this.http.delete<BaseResponse>(`${environment.apiUrl}api/GeoRols/UnassignGeoRol?userMappId=${_userMappId}`);
  }

}
