import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

// MODELOS
import {
  AppConstants,
  GetBPResponse,
  BaseResponse,
  PermsByRolResponse,
  PermissionsModel,
} from "./../models/index";
import { PermissionsSelectedResponse } from "../models/responses";
import { StorageService } from "./storage.service";
import { environment } from "src/environments/environment";

// RUTAS

// COMPONENTES

// SERVICIOS

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  GetPerms() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.get<PermissionsSelectedResponse>(
      `${environment.apiUrl}api/Permission/GetPermissions`,
      { headers }
    );
  }

  GetPermsByRol(idRol: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.get<PermsByRolResponse>(
      `${environment.apiUrl}api/Permission/GetPermissionsByRol?idRol=${idRol}`,
      { headers }
    );
  }

  savePermsByRol(permsRolModel) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.post<BaseResponse>(
      `${environment.apiUrl}api/Permission/AssignPermByRol/`,
      permsRolModel,
      { headers }
    );
  }

  getPermsByUser() {
    const currentSession = JSON.parse(this.storage.getCurrentSession());
    let userMappId = Number(currentSession.userMappId);
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentSession.access_token}`,
    });
    return this.http.get<PermissionsSelectedResponse>(
      `${environment.apiUrl}api/Permission/GetPermissionsByUserConsole?userMappId=${userMappId}`,
      { headers }
    );
  }

  // Actualiza el permiso, de momento solo actualiza la descripcion, pero se pueden agregar mas campos
  UpdatePermission(_id: number, _description: string) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const permissionsModel = {} as PermissionsModel;
    permissionsModel.Id = _id;
    permissionsModel.Description = _description;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.post<BaseResponse>(
      `${environment.apiUrl}api/Permission/UpdatePermission/`,
      permissionsModel,
      { headers }
    );
  }

  PostPermission(permission: PermissionsModel) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    return this.http.post<BaseResponse>(
      `${environment.apiUrl}api/Permission/PostPermission/`,
      permission,
      { headers }
    );
  }
}
