import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// MODELOS
import {
  UsersAssingsResponse, UsersResponse, BaseResponse, SalesManResponse, SalesManModel, WareHouseModel,
  WareHouseResponse,
  CompanyInfoModel,
  CompanyListResponse
} from '../../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { UserService, AlertService, SalesManService, WarehouseService, CompanyService } from '../../../services/index';

@Component({
  selector: 'app-users-assings-config',
  templateUrl: './users-assings-config.component.html',
  styleUrls: ['./users-assings-config.component.css']
})
export class UsersAssingsConfigComponent implements OnInit {

  IsEditing: boolean;
  @BlockUI() blockUI: NgBlockUI;
  userGroup: FormGroup;
  userId: string;
  btnSendInfo: string; // cambia el titulo de el boton de envio
  disable: boolean;
  user: any;
  userList: any;

  title: string;

  salesManList: SalesManModel[] = []; // lista de vendedores
  wareHouseList: WareHouseModel[] = []; // lista de almacenes
  CompanyList: CompanyInfoModel[] = []; // lista de companies

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private fbg: FormBuilder,
    private router: Router,
    private uService: UserService,
    private slpService: SalesManService,
    private whService: WarehouseService,
    private compService: CompanyService
  ) {
  }



  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId').toString();
    this.disable = false;
    if (this.userId !== '0') {
      this.IsEditing = true;
      this.disable = true;
      this.btnSendInfo = 'Guardar';
      this.title = 'Edición de asignación de usuario';
    } else {
      this.IsEditing = false;
      this.btnSendInfo = 'Crear';
      this.title = 'Asignar usuario a compañía';
    }

    this.userGroup = this.fbg.group({
      cUserName: ['', Validators.required],
      cSAPUser: ['', Validators.required],
      SAPPass: ['', Validators.required],
      WhCode: ['', Validators.required],
      CompanyId: ['', Validators.required],
      SlpCode: ['', Validators.required],
      CenterCost: ['', Validators.min(0)],
      Active: [true],
      SuperUser: [false]
    });
    this.chargeUser();
  }

  get f() { return this.userGroup.controls; }

  chargeUser() {
    this.blockUI.start();
    this.uService.getUsers()
      .subscribe((data: UsersResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.userList = data.userList;
          this.getSalesMan();
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  getSalesMan() {
    this.blockUI.start();
    this.slpService.getSalesMan()
      .subscribe((data: SalesManResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.salesManList = data.salesManList;
          this.getWareHouses();
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  getWareHouses() {
    this.blockUI.start();
    this.whService.getWareHouses()
      .subscribe((data: WareHouseResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.wareHouseList = data.wareHouseList;
          this.chargeCompanyList();
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  // carga la lista de Compañias que estan registradas en BD
  chargeCompanyList() {
    this.blockUI.start();
    this.compService.GetCompanys()
      .subscribe((data: CompanyListResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.CompanyList = data.CompanyList.filter(c => c.Active);
          if (this.userId !== '0') {
            this.chargeUserAssings();
          } else {
            this.userGroup.patchValue({ cUserName: this.userList[0].userId });
          }
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  chargeUserAssings() {
    this.blockUI.start();
    this.uService.getUsersAssings().subscribe((data: UsersAssingsResponse) => {
      this.blockUI.stop();
      if (data.result) {
        data.userList.forEach(element => {
          if (element.Id === parseInt(this.userId)) {
            this.user = element;
          }
        });
        this.charge();
      } else {
        this.alertService.WarningAlert(`${data.errorInfo.Message}`);
      }
    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`${error}`);
    });
  }

  charge() {
    this.userGroup.patchValue({ cUserName: this.user.UserId });
    this.userGroup.controls.cSAPUser.setValue(this.user.SAPUser);
    this.userGroup.controls.SAPPass.setValue(this.user.SAPPass);
    this.userGroup.controls.WhCode.setValue(this.user.WhCode);
    this.userGroup.controls.CompanyId.setValue(this.user.CompanyId);
    this.userGroup.controls.SlpCode.setValue(this.user.SlpCode);
    this.userGroup.controls.CenterCost.setValue(this.user.CenterCost);
    this.userGroup.controls.Active.setValue(this.user.Active);
    this.userGroup.controls.SuperUser.setValue(this.user.SuperUser);
  }

  onSubmitCreate() {
    if (this.userGroup.invalid) {
      return;
    }
    let slpCode = this.userGroup.controls['SlpCode'].value.toString();
    let slpName = this.salesManList.filter(x => x.SlpCode === slpCode);
    this.blockUI.start();
    this.uService.sendUserAssingsInfo(this.userGroup, this.userId, slpName[0].SlpName).subscribe((data: BaseResponse) => {
      this.blockUI.stop();
      if (data.result) {
        this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
        setTimeout(() => {
          this.router.navigate(['userAssings']);
        }, 500);
      } else {
        this.alertService.WarningAlert(`${data.errorInfo.Message}`);
      }
    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(`${error}`);
    });
  }

}
