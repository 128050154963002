import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

// SERVICES
import { DiscountHierarchiesService, AlertService } from 'src/app/services';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { DiscountHierarchy } from 'src/app/models';
import { Discounts } from 'src/app/enums';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-herachies',
  templateUrl: './herachies.component.html',
  styleUrls: ['./herachies.component.css']
})
export class HerachiesComponent implements OnInit, OnDestroy {

  Subscription$ = new Subscription();
  @Input() saveAction: EventEmitter<boolean>;
  @BlockUI() blockUI: NgBlockUI;
  discountHierarchy: DiscountHierarchy[] = [];

  constructor(private discountService: DiscountHierarchiesService,
    private alertService: AlertService) { }
  ngOnDestroy(): void {
    this.Subscription$.unsubscribe();
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.getDiscountHierarchy();
    this.Subscription$.add(this.saveAction.subscribe({
      next: (callback) => {
        if(callback)
        {
          this.PutDiscountHierarchy();
        }
      },
      error: (error) => {
        console.error(error);
      }
    }));
  }

  getDiscountHierarchy() {
    this.blockUI.start();
    this.discountService.GetDiscountHierarchy().pipe(finalize(()=> this.blockUI.stop())).subscribe(response => {
      if (response.result) {
        this.discountHierarchy = response.DiscountHierarchies;
      } else {
        this.discountHierarchy = [];
        this.alertService.ErrorAlert(response.errorInfo.Message);
        console.error(response.errorInfo.Message);
      }
    }, err => {
      console.error(err);
      this.alertService.ErrorAlert(err);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    this.moveItemInArray(this.discountHierarchy, event.previousIndex, event.currentIndex);
  }

  moveItemInArray(arr: any[], fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);

    this.SetHierarchy();
  }

  SetHierarchy(): void {
    if (!this.discountHierarchy) return;

    this.discountHierarchy.forEach((x, index) => {
      x.Hierarchy = index + 1;
    });
  }

  GetDiscountName(type: number): string {
    switch (type) {
      case Discounts.BP:
        return 'Socio de negocios';
      case Discounts.BPGroupXItem:
        return 'Grupo de clientes por artículo';
      case Discounts.BPGroupXItemGroup:
        return 'Grupo de clientes por Grupo de artículos';
      case Discounts.BPXItem:
        return 'Cliente por artículo';
      case Discounts.BPXItemFamily:
        return 'Cliente por Familia de artículos';
      case Discounts.BPXItemGroup:
        return 'Cliente por Grupo de artículos';
      case Discounts.ItemGroup:
        return 'Grupo de artículos';
      case Discounts.BlanketAgreements:
        return 'Acuerdos globales de ventas'
      default:
        return '';
    }
  }

  PutDiscountHierarchy(): void {
    this.blockUI.start();
    this.discountService.PutDiscountHierarchy(this.discountHierarchy).pipe(finalize(()=>this.blockUI.stop())).subscribe(response => {
      if (response.result) {
        this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
      } else {
        this.alertService.ErrorAlert(response.errorInfo.Message);
        console.error(response.errorInfo.Message);
      }
    }, err => {
      this.alertService.ErrorAlert(err);
      console.error(err);
    });
  }

}
