import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

// SERVICES
import { StorageService } from './storage.service';

// MODELS
import { AppConstants, User, StringModel } from './../models/index';
import { Globals } from '../globals';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient,
    private storage: StorageService,
    private router: Router,
    private globals: Globals) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public deleteCurrentUserValue(): any {
    this.currentUserSubject.next(null);
  }

  login(username: string, password: string) {

    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<any>(`${environment.apiUrl}token`, body.toString(), { headers })
      .pipe(map(user => {
        if (user && user.access_token) {
          this.storage.setCurrentSession(user);
          this.currentUserSubject.next(user);
        }
        return user;
      }));
  }

  logout() {
    this.globals.initPerms();
    this.storage.delete('currentUser');
    this.storage.delete('SelectedCompany');
    this.storage.delete('geoConfigs');
    this.currentUserSubject.next(null);
    this.router.navigateByUrl('login');
  }

  sendRecoverPswdEmail(user: FormGroup) {
    const userEmail: StringModel = {
      'word': user.value.email
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${environment.apiUrl}api/Account/SendRecoverPswdEmail`,
      userEmail,
      { headers });
  }

  recoverPswd(user: FormGroup) {

    let recoverPswd: User = {
      'Email': user.value.email,
      'Password': user.value.password
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${environment.apiUrl}api/Account/RecoverPswd`,
      recoverPswd,
      { headers });
  }

}
