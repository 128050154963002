import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, AppConstants } from 'src/app/models';
import { environment } from 'src/environments/environment';
import { StorageService } from '.';
import { IChart } from '../models/i-chart';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor(private http: HttpClient,
    private storage: StorageService) { }

  GetCharts(): Observable<ApiResponse<IChart[]>> {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });

    const URL = `${environment.apiUrl}api/Charts/GetCharts`;
    return this.http.get<ApiResponse<IChart[]>>(URL, {headers});
  }
}