import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import {
    AppConstants, DiscountHierarchiesResponse, DiscountHierarchy, BaseResponse
} from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DiscountHierarchiesService {

    constructor(private http: HttpClient,
        private storage: StorageService) { }

    GetDiscountHierarchy() {
        const TOKEN = JSON.parse(this.storage.getCurrentSession());

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN.access_token}`
        });

        return this.http.get<DiscountHierarchiesResponse>(
            `${environment.apiUrl}api/Items/GetDiscountHierarchies?userMappId=${TOKEN.userMappId}&lang=es&getOnlyActiveDiscount=${0}`,
            { headers }
        );
    }

    PutDiscountHierarchy(hierarchy: DiscountHierarchy[]) {
        const TOKEN = JSON.parse(this.storage.getCurrentSession());

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TOKEN.access_token}`
        });

        return this.http.put<BaseResponse>(`${environment.apiUrl}api/Items/PutDiscountHierarchy`, hierarchy, { headers });
    }
}
