import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { StorageService } from '../services';
import { AppConstants } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // checks the login or recovery paths
    if (request.url.includes(`token`) || request.url.includes('api/Account/SendRecoverPswdEmail') || request.url.includes('api/Account/RecoverPswd')) return next.handle(request); 

    const TOKEN = JSON.parse(this.storageService.getCurrentSession());

    let headers = request.headers.set(`Authorization`, `Bearer ${TOKEN.access_token}`);
    const clonedRequest = request.clone({headers});

    return next.handle(clonedRequest);
  }
}