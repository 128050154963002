import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import swal, { SweetAlertOptions } from 'sweetalert2';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;
  constructor(router: Router) {

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  // llama la funcion de alert con estado success
  // recibe el mensaje que se quiere mostrar
  SuccessAlert(message: string) {
    this.AlertaErrorMessage(message, 'success');
  }
  // llama la funcion del toast con estado success
  // recibe el mensaje que se quiere mostrar
  ToastSuccessAlert(message: string) {
    this.AlertMessage(message, 'success');
  }
  // llama la funcion de alert con estado error
  // recibe el mensaje que se quiere mostrar
  ErrorAlert(message: string) {
    this.AlertaErrorMessage(message, 'error');
  }
  // llama la funcion del toast con estado error
  // recibe el mensaje que se quiere mostrar
  ToastErrorAlert(message: string) {
    this.AlertMessage(message, 'error');
  }

  // llama la funcion de alert con estado info
  // recibe el mensaje que se quiere mostrar
  InfoAlert(message: string) {
    this.AlertaErrorMessage(message, 'info');
  }
  // llama la funcion del toast con estado info
  // recibe el mensaje que se quiere mostrar
  ToastInfoAlert(message: string) {
    this.AlertMessage(message, 'info');
  }

  // llama la funcion de alert con estado warning
  // recibe el mensaje que se quiere mostrar
  WarningAlert(message: string) {
    this.AlertaErrorMessage(message, 'warning');
  }
  // llama la funcion del toast con estado warning
  // recibe el mensaje que se quiere mostrar
  ToastWarningAlert(message: string) {
    this.AlertMessage(message, 'warning');
  }

  // muestra un toad en la parte superior de la pantalla informado sobre un determinado mensaje.
  // recibe el mensaje que se quiere mostrar y el tipo de mensaje, ya sea success, error, warning, info
  private AlertMessage(msn: any, tipo: any) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000
    });
    toast.fire({
      type: tipo,
      title: msn
    });
  }

  // muestra el mensaje de alerta  en el centro de la pagina
  // recibe el mensaje que se quiere mostrar y el tipo de mensaje, ya sea success, error, warning, info
  private AlertaErrorMessage(msn: any, tipo: any) {
    swal.fire({
      type: tipo,
      text: msn,
    });
  }

  confirmationAlert(titulo: string, msg: string, confirmText: string) {
    let settings: SweetAlertOptions = {
      title: titulo,
      text: msg,
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmText,
      cancelButtonText: 'Cancelar'
    }

    return Swal.fire(settings).then((result) => {
      return result.value;
    });
  }

  confirmationWithTextAreaAlert(titulo: string, msg: string, confirmText: string) {
    let settings: SweetAlertOptions = {
      title: titulo,
      text: msg,
      type: 'question',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: confirmText,
      cancelButtonText: 'Cancelar',
      confirmButtonClass: 'btn btn-outline-secondary',
      cancelButtonClass: 'btn btn-outline-secondary'
    }

    return Swal.fire(settings).then((result) => {
      return result;
    });
  }

  informationAlert(titulo: string, msg: string, confirmText: string) {
    let settings: SweetAlertOptions = {
      title: titulo,
      html: msg,
      type: 'info',
      confirmButtonColor: '#3085d6',
      confirmButtonText: confirmText,
    }

    return Swal.fire(settings).then((result) => {
      return result.value;
    });
  }
}
