import { Component, OnInit } from '@angular/core';
import { GeoConfig } from 'src/app/models';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GeoRolsService, AlertService } from 'src/app/services';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-geo-permissions',
  templateUrl: './geo-permissions.component.html',
  styleUrls: ['./geo-permissions.component.css']
})
export class GeoPermissionsComponent implements OnInit {
  G_UptGeoPerms: boolean;
  availableGeoConfigs: GeoConfig[];
  @BlockUI() blockUI: NgBlockUI;
  rolModal: NgbModalRef;
  permName: string;
  permKey: number;

  constructor(private modalService: NgbModal,
    private geoRolsService: GeoRolsService,
    private alertService: AlertService,
    private globals: Globals) { }

  ngOnInit() {
    this.G_UptGeoPerms = this.globals.perms.some(p => p.Name === 'G_UptGeoPerms');
    this.geoRolsService.GetGeoConfigs(0).subscribe(response => {
      this.availableGeoConfigs = response.GeoConfigs;
    });
  }

  onClickEditGeoConfi(_key: number, modalContent: any): void {
    this.permName = this.availableGeoConfigs.filter(x => x.Key === _key)[0].Name;
    this.permKey = _key;
    this.rolModal = this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  onClickDismissModal() {
    this.rolModal.close();
  }

  savePermGeo(): void {

    if(!this.G_UptGeoPerms)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    this.blockUI.start();
    const GEO_CONFIG = {} as GeoConfig;
    GEO_CONFIG.Key = this.permKey;
    GEO_CONFIG.Name = this.permName;
    this.geoRolsService.PutGeoConfig(GEO_CONFIG).subscribe(response => {
      console.log(response);
      this.rolModal.close();
      this.blockUI.stop();
      if (response.result) {
        this.availableGeoConfigs.forEach(x => {
          if (x.Key === this.permKey) {
            x.Name = this.permName;
          }
        });
        this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
      } else {
        this.alertService.ErrorAlert(`Error al actualizar el permiso, detalle: ${response.errorInfo.Message}`);
      }
    });
  }
}
