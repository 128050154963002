import { Component, OnInit } from '@angular/core';
import { IStashedDocument } from '../../models/i-document';
import { DocumentsService } from '../../services/documents.service';
import { DocumentTypes, TransactionStatus, TransactionTypes } from '../../models/constantes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../services/alert.service';
import { IGlobalSearch } from '../../models/i-global-search';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-stashed-documents',
  templateUrl: './stashed-documents.component.html',
  styleUrls: ['./stashed-documents.component.css']
})
export class StashedDocumentsComponent implements OnInit {
  // varbox
  @BlockUI() blockUI: NgBlockUI;
  stashedDocuments: IStashedDocument[];
  pagedStashedDocuments: IStashedDocument[];
  transactionStatus = TransactionStatus; 
  TransactionTypes = TransactionTypes;
  searchForm: FormGroup;
  activeDocument: IStashedDocument;
  totalRows: number;
  currentPage: number;
  rowsByPage: number;

  constructor(
    private documentService: DocumentsService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.InitVariables();
    this.ResetSearchForm();
    this.LoadStashedDocuments();
  }

  InitVariables(){
    this.totalRows = 1;
    this.rowsByPage = 15;
    this.currentPage = 1;
    this.stashedDocuments = [];
    this.pagedStashedDocuments = [];
  }

  ResetSearchForm(): void{
    this.searchForm = this.formBuilder.group({
      UserMapId: [-1],
      Search: [''],
      Since: ['', Validators.required],
      Until: ['', Validators.required],
      Status: [-1],
      TransactionType: [""]
    });
  }

  LoadStashedDocuments(): void{
    let globalSearch = this.searchForm.value as IGlobalSearch;
    let offset = (this.currentPage - 1) * this.rowsByPage;
    let rows = this.rowsByPage;
    this.blockUI.start(`Procesando, por favor`);
    this.documentService.GetPagedBackedupDocuments(globalSearch, offset, rows).pipe(finalize(() => this.blockUI.stop())).subscribe(req => {
      try {
        if(req.result){
          this.totalRows = req.Data.TotalCount;
          this.pagedStashedDocuments = req.Data.PagedDocuments;
        } else {
          this.alertService.ErrorAlert(req.errorInfo.Message);
        }  
      }
      catch (error) {
        console.log(error);
      }
    }, error => {
      this.alertService.ErrorAlert(`${error}`);
      console.log(error);
    });
  }

  GetStatus(status: number): any {
    return this.transactionStatus.find(x => x.Value == status);
  }

  GetDocumentType(_transactionType: string): any {
    const DOCUMENT = this.TransactionTypes.find(x => x.Value == _transactionType);

    return DOCUMENT ? DOCUMENT : { Name: "-" };
  }

  ShowErrorModal(content: NgbModalRef, document: IStashedDocument): void {
    this.activeDocument = document;
    this.activeDocument['jsonDocument'] = JSON.parse(document.RawDocument); 
    this.modalService.open(content, { size: 'lg' });
  }

  PageChange(): void {
    this.LoadStashedDocuments();
  }

  ResultsChange(): void {
    this.currentPage = 1;
    this.LoadStashedDocuments();
  }
}
