import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import { AppConstants, RolsResponse, RolUserResponse, RolUserModel, BaseResponse, RolsModel } from './../models/index';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// RUTAS

// COMPONENTES

// SERVICIOS

@Injectable({
  providedIn: 'root'
})
export class RolService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  // obtiene los roles del owner de la cuenta
  getRoles() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.get<RolsResponse>(`${environment.apiUrl}api/Rol/GetRols`,
      { headers }
    );
  }

  // metodo para obtener una lista de las asignaciones por rol y compañia
  GetRolsByUser(rolId: number, userMappId: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.get<RolUserResponse>(`${environment.apiUrl}api/Rol/GetRolsByUser?rolId=${rolId}&userMappId=${userMappId}`,
      { headers }
    );
  }

  // metodo para realizar la asignacion de un rol a un usuario
  setAssignRolByUser(rolUserCompany: RolUserModel) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.post<BaseResponse>(`${environment.apiUrl}api/Rol/AssignRolByUser/`,
      rolUserCompany,
      { headers }
    );

  }

  handlePostOrPutRol(rol: RolsModel) {
    const currentSession = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${currentSession.access_token}`
    });
    const endPoint = rol.Id > 0 ? 'api/Rol/PutRol' : 'api/Rol/PostRol';

    return this.http.post<BaseResponse>(`${environment.apiUrl}${endPoint}`, rol, { headers });
  }

  UnassignRolByUser(_rol: RolUserModel): Observable<BaseResponse>
  {
    return this.http.post<BaseResponse>(`${environment.apiUrl}api/Rol/UnassignRolByUser`, _rol);
  }

}
