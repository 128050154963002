import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Subscription } from "rxjs";
import { filter, first } from "rxjs/operators";
import { Email, Parameter, ReportParameter } from "src/app/models";
import {
  AlertService,
  CommonService,
  RptManagerService,
} from "src/app/services";

@Component({
  selector: "app-print-report",
  templateUrl: "./print-report.component.html",
  styleUrls: ["./print-report.component.css"],
})
export class PrintReportComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;

  reportId: number;
  routerEventsSubs: Subscription;

  parameters: ReportParameter[];
  parameterSubgroup1: ReportParameter[];
  parameterSubgroup2: ReportParameter[];
  parameterSubgroup3: ReportParameter[];
  parameterSubgroup4: ReportParameter[];
  paramaterListGroup: ReportParameter[][];

  parametersForm: FormGroup;
  emailForm: FormGroup;
  emailRecipients: string[];
  reportKeys: Parameter[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private rptManagerService: RptManagerService,
    private alertService: AlertService,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      Subject: ["", Validators.required],
      Body: ["", Validators.required],
      Recipient: [""],
    });
    this.initApp();
    this.routerEventsSubs = this.router.events
      .pipe(filter((event$) => event$ instanceof NavigationEnd))
      .subscribe(() => this.initApp());
  }

  ngOnDestroy() {
    if (this.routerEventsSubs) this.routerEventsSubs.unsubscribe();
  }

  resetEmailForm() {
    this.emailForm.reset({
      Subject: "",
      Body: "",
      Recipient: "",
    });
  }

  resetParameterForm() {
    for (const control in this.parametersForm.controls) {
      this.parametersForm.get(control).reset();
    }
  }

  initApp() {
    this.reportId = Number(this.route.snapshot.paramMap.get("reportId"));
    this.parametersForm = this.formBuilder.group({});
    this.resetEmailForm();
    this.emailRecipients = [];
    this.parameters = [];
    this.reportKeys = [];
    this.parameterSubgroup1 = [];
    this.parameterSubgroup2 = [];
    this.parameterSubgroup3 = [];
    this.parameterSubgroup4 = [];
    this.paramaterListGroup = [];
    this.paramaterListGroup.push(
      this.parameterSubgroup1,
      this.parameterSubgroup2,
      this.parameterSubgroup3,
      this.parameterSubgroup4
    );
    this.getParameters();
  }

  getParameters() {
    this.rptManagerService
      .GetParameters(this.reportId)
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result) {
            this.parameters = response.Parameters;

            response.Parameters.forEach((x) => {
              switch (x.GridCol) {
                case 0:
                  this.parameterSubgroup1.push(x);
                  break;
                case 1:
                  this.parameterSubgroup2.push(x);
                  break;
                case 2:
                  this.parameterSubgroup3.push(x);
                  break;
                case 3:
                  this.parameterSubgroup4.push(x);
                  break;
              }
            });

            this.setFormControls();
          } else {
            this.alertService.ErrorAlert(response.ErrorInfo.Message);
          }
        },
        (err) => {
          this.alertService.ErrorAlert(err);
        }
      );
  }

  addRecipient(recipient: string) {
    if (!recipient) return;
    if (!this.emailRecipients.some((x) => x === recipient))
      this.emailRecipients.push(recipient);
    this.emailForm.get("Recipient").reset();
  }

  onClickRemoveRecipient(index: number) {
    this.emailRecipients.splice(index, 1);
  }

  onClickPrintReport() {
    const parameters: Parameter[] = this.getParametersForReportPrint();

    this.blockUI.start();

    this.rptManagerService
      .PrintReport(parameters, this.reportId)
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();

          if (response.Result) {
            this.commonService.downloadFile(
              response.Print,
              "Report",
              "application/pdf",
              "pdf"
            );
          } else {
            this.alertService.ErrorAlert(response.ErrorInfo.Message);
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.ErrorAlert(err);
        }
      );
  }

  onClickSendEmail() {
    let email: Email = {
      Body: this.emailForm.get("Body").value,
      Recipients: this.emailRecipients,
      Subject: this.emailForm.get("Subject").value,
      Parameters: this.getParametersForReportPrint(),
    };

    this.blockUI.start();

    this.rptManagerService
      .SendEmail(email, this.reportId)
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();
          if (response.Result) {
            this.alertService.ToastSuccessAlert("Correo enviado exitosamente");

            this.resetEmailForm();
            this.emailRecipients = [];
            this.dismissModal(true);
          } else {
            this.alertService.ErrorAlert(response.ErrorInfo.Message);
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.ErrorAlert(err);
        }
      );
  }

  getParametersForReportPrint() {
    let parameters: Parameter[] = [];
    if (this.parameters) {
      this.parameters.forEach((x) => {
        let parameter: Parameter = {
          Key: x.Name,
          Type: x.Type,
          Value: this.parametersForm.get(x.Name).value,
        };

        parameters.push(parameter);
      });
    }

    return parameters;
  }

  setFormControls() {
    this.parameters.forEach((x) => {
      this.parametersForm.addControl(
        x.Name,
        !x.Required
          ? new FormControl("")
          : new FormControl("", {
              validators: Validators.required,
            })
      );
    });
  }

  onClickSendMailModal(modalSendMail: any) {
    this.modalService.open(modalSendMail, {
      backdrop: true,
      size: "lg",
    });
  }

  dismissModal(result: boolean) {
    this.modalService.dismissAll(result);
  }

  get emailFormControls() {
    return this.emailForm.controls;
  }
}
