import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IPermissionsSelectedModel } from './models';

@Injectable()
export class Globals {

  perms: IPermissionsSelectedModel[] = [];
  sidebarToggler: Subject<boolean>;

  constructor() {
    this.sidebarToggler = new Subject();
  }

  initPerms() {
    this.perms = [];
  }

}
