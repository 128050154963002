import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '../../../services/permissions.service';
import { IPermissionsSelectedModel } from 'src/app/models';
import { AlertService } from 'src/app/services';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'src/app/globals';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-permissions-crud',
  templateUrl: './permissions-crud.component.html',
  styleUrls: ['./permissions-crud.component.css']
})
export class PermissionsCrudComponent implements OnInit {
  CanEditPerm: boolean;
  permissionsList: IPermissionsSelectedModel[];
  @BlockUI() blockUI: NgBlockUI;
  permModal: NgbModalRef;
  permDescription: string;
  permName: string;
  permId: number;
  constructor(private permissionsService: PermissionsService, private alertService: AlertService,
    private modalService: NgbModal,
    private globals: Globals) { }
    
  ngOnInit() {
    this.CanEditPerm = this.globals.perms.some(p => p.Name === 'G_UptPerms');
    this.blockUI.start();
    this.permissionsService.GetPerms()
    .pipe(finalize(()=>this.blockUI.stop()))
    .subscribe(
    {
      next: (response) => {
        if (response.result) 
        {
          if(response.errorInfo)
          {
            this.alertService.ToastInfoAlert(response.errorInfo.Message);
          }
          else
          {
            this.permissionsList = response.PermissionsList;
          }
        } 
        else 
        {
          this.alertService.ErrorAlert(response.errorInfo.Message);
        }
      },
      error: (error) =>{
        this.alertService.ErrorAlert(error);
      }
    });
  }

  onClickDismissModal() {
    this.permModal.close();
  }

  onClickEditperm(_id: number, modalContent: any): void {
    this.permDescription = this.permissionsList.filter(x => x.Id === _id)[0].Description;
    this.permName = this.permissionsList.filter(x => x.Id === _id)[0].Name;
    this.permId = _id;
    this.permModal = this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  // Actualiza la descripcion del permiso
  savePerm(): void {
    this.blockUI.start();
    this.permissionsService.UpdatePermission(this.permId, this.permDescription).subscribe(response => {
      this.blockUI.stop();
      this.permModal.close();
      if (response.result) {
        this.permissionsList.forEach(x => {
          if (x.Id === this.permId) {
            x.Description = this.permDescription;
          }
        });
        this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
      } else {
        this.alertService.ErrorAlert(`Ocurrió un error actualizando el permiso ${response.errorInfo.Message}`);
      }
    });
  }
}
