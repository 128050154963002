import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
import { Menu } from '../models/constantes';
import { MenuOption } from '../models/menu-option';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {
  MenuOptions: MenuOption[];
  constructor(private globals: Globals){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      this.MenuOptions = Menu;

      let menuOption = this.MenuOptions.find(mo => mo.Route === next.routeConfig.path);

      if(!menuOption)
      {
        this.MenuOptions.forEach(mo => {
          if(!menuOption) menuOption = mo.Children.find(moc => moc.Route === next.routeConfig.path);
        });
      }

      if(!menuOption || !menuOption.RequiredPerm) return true;

      return this.globals.perms.some(p => p.Name === menuOption.RequiredPerm);
  }
}
