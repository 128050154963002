import { Component, OnInit, ViewChild } from "@angular/core";
import { NgBlockUI, BlockUI } from "ng-block-ui";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable, Subject, merge } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

import { CompanyUDF, IPermissionsSelectedModel } from "src/app/models";
import { CompanyService, AlertService } from "src/app/services";
import { DocumentType, UDFFieldType } from "src/app/enums";
import { Globals } from "src/app/globals";

@Component({
  selector: "app-udfs-view",
  templateUrl: "./udfs.component.html",
  styleUrls: ["./udfs.component.css"],
})
export class UdfsViewComponent implements OnInit {
  G_CreateUDF: boolean;
  G_EditUDF: boolean;
  G_DeleteUDF: boolean;
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild("ngbTabset") tabset;
  @ViewChild("typeAheadUDFName") typeAheadUDFName: NgbTypeahead;
  focusUDFNameInput$ = new Subject<string>();
  clickUDFNameInput$ = new Subject<string>();

  UDFsToMap: CompanyUDF[];
  UDFs: CompanyUDF[];
  UDFInEdition: CompanyUDF;

  udfForm: FormGroup;
  customValueForm: FormGroup;
  validValues: UDFValidValue[];

  currentPage: number;
  rowsByPage: number;
  pagedUDFs: CompanyUDF[];

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private alertService: AlertService,
    private globals: Globals
  ) {}

  ngOnInit() {
    this.initializeApp();
    this.getUDFsToMapp();
    this.getCompanyUDFs();
  }

  initializeApp() {
    this.G_CreateUDF = this.globals.perms.some(p => p.Name === 'G_CreateUDF');
    this.G_DeleteUDF = this.globals.perms.some(p => p.Name === 'G_DeleteUDF');
    this.G_EditUDF = this.globals.perms.some(p => p.Name === 'G_EditUDF');
    this.UDFs = [];
    this.currentPage = 1;
    this.rowsByPage = 10;
    this.pagedUDFs = [];

    this.udfForm = this.formBuilder.group({
      Name: ["", Validators.required],
      DescriptionES: ["", Validators.required],
      DescriptionEN: ["", Validators.required],
      FieldType: [1, Validators.required],
      TargetDocument: [1, Validators.required],
    });

    this.customValueForm = this.formBuilder.group({
      Value: ["", Validators.required],
      Description: ["", Validators.required],
    });

    this.validValues = [];
    this.UDFInEdition = null;
  }

  resetUDFForm() {
    this.udfForm.patchValue({
      Name: "",
      DescriptionES: "",
      DescriptionEN: "",
      FieldType: 1,
    });
  }

  resetCustomValueForm() {
    this.customValueForm = this.formBuilder.group({
      Value: "",
      Description: "",
    });
  }

  getCompanyUDFs() {
    this.companyService.GetCompanyUDFs().subscribe(
      (response) => {
        if (response.result) {
          this.UDFs = response.UDFs;
          this.PageChange();
        } else {
          this.UDFs = [];
          this.PageChange();
          this.alertService.ErrorAlert(response.errorInfo.Message);
        }
      },
      (err) => {
        this.alertService.ErrorAlert(err);
      }
    );
  }

  getUDFCategory(category: number) {
    switch (category) {
      case DocumentType.BusinessPartner:
        return "Socios de negocios";
      case DocumentType.CreditInvoice:
      case DocumentType.ReserveInvoice:
      case DocumentType.CashInvoice:
        return "Factura";
      case DocumentType.SaleOrder:
        return "Orden de venta";
      case DocumentType.AccountPayment:
      case DocumentType.InvoicePayment:
      case DocumentType.IncommingPayment:
        return "Pago recibido";
      case DocumentType.SaleOffer:
        return "Oferta de venta";
      case DocumentType.Delivery:
        return "Entregas";
    }
  }

  get udfFormControls() {
    return this.udfForm.controls;
  }

  onClickAddValidValue() {
    let newValidValue: UDFValidValue;
    newValidValue = {
      Description: this.customValueForm.get("Description").value,
      Value: this.customValueForm.get("Value").value,
    };

    this.validValues.push(newValidValue);
    this.customValueForm.reset();
  }

  onClickDeleteValidValue(index: number) {
    this.validValues.splice(index, 1);
  }

  onClickEditUDF(UDF: CompanyUDF) {
    if(!this.G_EditUDF)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    this.patchValuesUDFForm(UDF);
    this.parseValidValuesStringAsUDFValidValueList(UDF.ValidValues);
    this.UDFInEdition = { ...UDF };
    this.tabset.activeId = "tabCrud";
  }

  patchValuesUDFForm(UDF: CompanyUDF) {
    this.udfForm.patchValue({
      Name: UDF.Name,
      DescriptionES: UDF.DescriptionES,
      DescriptionEN: UDF.DescriptionEN,
      FieldType: UDF.FieldType,
      TargetDocument: UDF.TargetDocument,
    });
  }

  UDFModelFromForm() {
    let temporalModel: CompanyUDF = {
      CompanyId: this.UDFInEdition ? this.UDFInEdition.CompanyId : 0,
      DescriptionEN: this.udfForm.get("DescriptionEN").value,
      DescriptionES: this.udfForm.get("DescriptionES").value,
      FieldType: this.udfForm.get("FieldType").value,
      Id: this.UDFInEdition ? this.UDFInEdition.Id : 0,
      Name: this.udfForm.get("Name").value,
      TargetDocument: this.udfForm.get("TargetDocument").value,
      ValidValues:
        this.validValues && this.validValues.length > 0
          ? JSON.stringify(this.validValues)
          : "",
    };

    return temporalModel;
  }

  parseValidValuesStringAsUDFValidValueList(validValuesString: string) {
    this.validValues = [];
    if (validValuesString) this.validValues = JSON.parse(validValuesString);
    // if (!validValuesString) return;

    // let validValues = validValuesString.split(",");

    // if (!validValues) return;

    // validValues.forEach((x) => {
    //   let valueModel = x.split("-");
    //   this.validValues.push({
    //     Value: valueModel[0],
    //     Description: valueModel[1],
    //   });
    // });
  }

  onTabChange(tabsState: any) {
    if (tabsState.nextId === "tabList") {
      this.UDFInEdition = null;
      this.validValues = [];
      this.resetCustomValueForm();
      this.resetUDFForm();
    }
  }

  onChangeTargetDocument() {
    this.getUDFsToMapp();
    this.resetUDFForm();
    this.validValues = [];
  }

  onClickSaveUDF() {

    if((this.UDFInEdition && !this.G_EditUDF) || (!this.UDFInEdition && !this.G_CreateUDF))
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    this.blockUI.start();

    if (!this.udfExistsInListFromSAP(this.udfForm.get("Name").value)) {
      this.blockUI.stop();
      this.alertService.ErrorAlert("El UDF ingresado no se encuentra en SAP");

      return;
    }

    this.UDFInEdition = this.UDFModelFromForm();

    this.companyService.handlePostOrPutCompanyUDF(this.UDFInEdition).subscribe(
      (response) => {
        this.blockUI.stop();
        if (response.result) {
          this.alertService.ToastSuccessAlert("Proceso finalizado exitosamente");
          this.initializeApp();
          this.getCompanyUDFs();
          this.tabset.activeId = "tabList";
        } else {
          this.alertService.ToastErrorAlert(response.errorInfo.Message);
        }
      },
      (err) => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(err);
      }
    );
  }

  getUDFsToMapp() {
    this.blockUI.start();
    this.companyService
      .GetUDFsToMapp(+this.udfForm.get("TargetDocument").value)
      .subscribe(
        (response) => {
          this.blockUI.stop();
          if (response.result) {
            this.UDFsToMap = response.UDFs;
          }
        },
        (err) => {
          this.blockUI.stop();
          console.log(err);
        }
      );
  }

  searchUDF = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickUDFNameInput$.pipe(
      filter(() => !this.typeAheadUDFName.isPopupOpen())
    );

    return merge(
      debouncedText$,
      this.focusUDFNameInput$,
      clicksWithClosedPopup$
    ).pipe(
      map((term) =>
        (!term
          ? this.UDFsToMap.filter(
              (x) =>
                !this.UDFs.some(
                  (y) =>
                    y.Name === x.Name &&
                    y.TargetDocument ===
                      +this.udfForm.get("TargetDocument").value
                )
            ).map((x) => x.Name)
          : this.UDFsToMap.filter(
              (x) =>
                x.Name.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
                !this.UDFs.some(
                  (y) =>
                    y.Name === x.Name &&
                    y.TargetDocument ===
                      +this.udfForm.get("TargetDocument").value
                )
            ).map((x) => x.Name)
        ).slice(0, 10)
      )
    );
  };

  onClickUDFNameSelected(event) {
    let UDFName = event.item;
    let UDF = this.UDFsToMap.find((x) => x.Name === UDFName);

    if (!UDF) return;

    this.patchValuesUDFForm(UDF);
    if (UDF.ValidValues) this.validValues = JSON.parse(UDF.ValidValues);
  }

  async onClickDeleteUDF(Id: number, index: number) {

    if(!this.G_DeleteUDF)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    let result = await this.alertService.confirmationAlert(
      `¿Eliminar mapeo de UDF?`,
      "El campo ya no estará disponible para su uso en el móvil",
      "Eliminar"
    );

    if (result) {
      this.blockUI.start();

      this.companyService.DeleteUDF(Id).subscribe(
        (response) => {
          this.blockUI.stop();

          if (response.result) {
            this.alertService.ToastSuccessAlert(
              "Proceso finalizado exitosamente"
            );
            this.UDFs.splice(index, 1);
            this.getCompanyUDFs();
          } else {
            this.alertService.ErrorAlert(
              `Ocurrió un error al eliminar el UDF ${response.errorInfo.Message}`
            );
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.ErrorAlert(err);
        }
      );
    }
  }

  udfExistsInListFromSAP(UDFName: string) {
    if (this.UDFInEdition) return true;
    if (!this.UDFsToMap) return false;
    if (!UDFName) return false;

    return this.UDFsToMap.some((x) => x.Name === UDFName);
  }

  PageChange(): void {
    this.pagedUDFs = this.UDFs.slice(this.rowsByPage * (this.currentPage - 1), this.rowsByPage * this.currentPage);
  }

  GetUDFTypeName(_udfType: number): string
  {
    switch(_udfType)
    {
      case UDFFieldType.Numeric:
        return "Numérico";
      case UDFFieldType.Alpha:
        return "Alfanumérico";
      case UDFFieldType.Select:
        return "Opción múltiple";
      case UDFFieldType.Date:
        return "Fecha";
      case UDFFieldType.Decimal:
        return "Decimal";
      default:
        return "Indefinido";
    }
  }
}

export interface UDFValidValue {
  Value: any;
  Description: any;
}
