import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { first } from 'rxjs/operators';
import { Globals } from "src/app/globals";
import { StorageService } from '../storage.service';
// Models
import {
  AppConstants,
  BaseResponse,
  Email,
  Parameter,
  Report,
} from "src/app/models";

@Injectable({
  providedIn: "root",
})
export class RptManagerService {
  constructor(private http: HttpClient, private globals: Globals, private storageService: StorageService) {}

  SaveReportFile(report: File) {
    const formData = new FormData();
    formData.append("report", report);

    return this.http.post<BaseResponse>(
      `${this.storageService.GetReportManagerURL()}api/Reports/SaveReportFile?companyKey=0&appKey=${AppConstants.KeyReportManager}`,
      formData
    );
  }

  HandlePostOrPutReport(report: Report) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    if (report.Id > 0) {
      return this.http.put<any>(
        `${this.storageService.GetReportManagerURL()}api/Reports/PutReport`,
        report,
        { headers }
      );
    } else {
      return this.http.post<any>(
        `${this.storageService.GetReportManagerURL() }api/Reports/PostReport`,
        report,
        { headers }
      );
    }
  }

  GetReports() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${this.storageService.GetReportManagerURL()}api/Reports/GetReports?companyKey=0&appKey=${AppConstants.KeyReportManager}`,
      { headers }
    );
  }

  GetParameters(reportId: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${this.storageService.GetReportManagerURL()}api/Parameter/GetParameters?reportId=${reportId}`,
      { headers }
    );
  }

  PrintReport(parameters: Parameter[], reportId: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.post<any>(
      `${this.storageService.GetReportManagerURL()}api/Reports/PrintReport?reportId=${reportId}`,
      parameters,
      { headers }
    );
  }

  SendEmail(emailInfo: Email, reportId: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.post<any>(
      `${this.storageService.GetReportManagerURL()}api/Reports/SendEmail?reportId=${reportId}`,
      emailInfo,
      { headers }
    );
  }

  DownloadFile(reportId: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${this.storageService.GetReportManagerURL()}api/Reports/DownloadFile?reportId=${reportId}`,
      { headers }
    );
  }

  GetReportUsers() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${this.storageService.GetReportManagerURL()}api/ReportUser/GetReportUsers`,
      { headers }
    );
  }

  DeleteReport(reportId: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.delete<any>(
      `${this.storageService.GetReportManagerURL()}api/Reports/DeleteReport?reportId=${reportId}`,
      { headers }
    );
  }

  GetApplicationId(appKey: number): Promise<number> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token.access_token}`
    });

    return new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${this.storageService.GetReportManagerURL()}api/Reports/GetApplicationId?appKey=${appKey}`,
          { headers }
        )
        .pipe(first())
        .subscribe(
          (response) => {
            if (response.Result) {
              resolve(response.Data);
            } else {
              reject(null);
            }
          },
          () => {
            reject(null);
          }
        );
    });
  }

  AddReportsToMenu(reports: Report[],defaultMenuOption: any) {
    reports.forEach(report => {
        this.AddReportToMenu(report, defaultMenuOption);
    });
  }

  private TargetMenuOption(layoutConfig: any, menu: any[]) {
    if (!layoutConfig || !menu) return null;

    let targetNode = menu.find((x) => x.Name == layoutConfig.Name);

    if (layoutConfig.GoesTo && targetNode)
      return this.TargetMenuOption(layoutConfig.GoesTo, targetNode.Children);
    else return targetNode;
  }

  private AddReportToMenu(report: Report, menuOption: any) {
    if (!menuOption) return;
    if (!menuOption.Children) menuOption.Children = [];

    let reportIndex= menuOption.Children.findIndex(x => x.Name === report.DisplayName);

    if(reportIndex >= 0) menuOption.Children.splice(reportIndex, 1);

    menuOption.Children.push({
      Name: report.DisplayName,
      Route: `print-report/${report.Id}`,
      Icon: "",
      Children: [],
      Selected: false,
      Type: 2,
      Visible: true,
      RequiredPerm: "",
    });
  }

  hasPerm(requiredPerm: string) {
    let perms = this.globals.perms;
    if (!perms) return false;
    if (!requiredPerm) return true;

    return perms.some((x) => x.Name === requiredPerm);
  }
}
