import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// SERVICIOS
import { AuthenticationService, StorageService, AlertService, UserService } from '../../services/index';
import { CompanyService } from '../../services/company.service';
import { GoogleLoadScriptService } from '../../services/google-load-script.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isLogin: boolean;
  loading = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private auth: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private storageService: StorageService,
    private companyService: CompanyService,
    private loadScriptService: GoogleLoadScriptService
  ) {
    if (this.auth.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.isLogin = true;

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get fLogin() { return this.loginForm.controls; }

  // funcion para el envio del formulario, tanto de login como de signin
  onSubmit() {

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.alertService.ToastInfoAlert(`El formulario contiene errores`);
      return;
    }
    this.loading = true;

    if (this.isLogin) {
      this.auth.login(this.fLogin.email.value, this.fLogin.password.value).subscribe(data => {
        this.loading = false;
        this.getUserGeoConfigs();
        this.loadScriptService.LoadScript();
        this.alertService.ToastSuccessAlert('Bienvenido');
        this.router.navigateByUrl('home');
      }, error => {
        this.loading = false;
        this.alertService.ErrorAlert(`Error: ${error}`);
      });
    } else {
      this.auth.sendRecoverPswdEmail(this.loginForm).subscribe((data: any) => {
        this.loading = false;
        if (data.result) {
          this.alertService.ToastSuccessAlert('Correo enviado exitosamente');
        } else {
          this.alertService.WarningAlert(`Ocurrió un error enviando el correo ${data.errorInfo.Message}`);
        }
      }, error => {
        this.loading = false;
        this.alertService.ErrorAlert(error);
      });
    }
  }

  /*
  * Funcion para cambiar entre el formulario de login y de sigin
  */
  clickEvent(islogin: boolean) {
    this.isLogin = islogin;
    // se activa el form de login
    if (this.isLogin) {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
        password: ['', Validators.required]
      });
      // se activa el form de recuperar la contrasenna
    } else {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]]
      });
    }
  }

  getUserGeoConfigs() {
    let geoConfigs: any[] = [];
    this.userService.getGeoconfigs().subscribe(data => {

      if (data.result) {
        data.GeoConfigs.forEach(x => {
          geoConfigs.push({
            Name: x.Name,
            Key: x.Key
          });
        });

        this.storageService.set('geoConfigs', geoConfigs);
      }
    });
  }

}
