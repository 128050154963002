import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormControl } from '@angular/forms';

// MODELOS

import { BusinessPartnersMobileResponse, BusinessPartnersMobileModel } from './../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS

import { BussinesPartnersService, AlertService } from './../../services/index';
import { BaseResponse } from '../../models/responses';
import { finalize } from 'rxjs/operators';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-bpview',
  templateUrl: './bpview.component.html',
  styleUrls: ['./bpview.component.css']
})
export class BPViewComponent implements OnInit {
  G_ActivateCustomer: boolean;
  @BlockUI() blockUI: NgBlockUI;


  BPList: BusinessPartnersMobileModel[] = [];

  FechaIni: FormControl;
  FechaFin: FormControl;
  date: Date; // fecha actual
  nowDate: any;
  title: string;

  constructor(
    private bpService: BussinesPartnersService, 
    private alertService: AlertService,
    private globals: Globals) {
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.G_ActivateCustomer = this.globals.perms.some(p => p.Name === 'G_ActivateCustomer');
    this.date = new Date();
    this.BPList = [];
    this.nowDate = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;
    this.FechaIni = new FormControl(this.nowDate);
    this.FechaFin = new FormControl(this.nowDate);
    this.title = 'Activación de clientes';
    this.getAllBPToActive();
  }

  getAllBPToActive() {
    let fechaIni = this.FechaIni.value;
    let fechaFin = this.FechaFin.value;

    if(fechaIni > fechaFin)
    {
      this.alertService.InfoAlert('La fecha de inicio debe ser menor a la fecha final');
    }

    this.blockUI.start();

    this.bpService.GetCustomersToActive(fechaIni, fechaFin).pipe(finalize(() => this.blockUI.stop()))
      .subscribe((data: BusinessPartnersMobileResponse) => {
        this.BPList = [];
        
        if (data.result) 
        {
          if(data.errorInfo)
          {
            this.alertService.InfoAlert(data.errorInfo.Message);
          }
          else
          {
            this.BPList = data.customersList;
          }
        } 
        else 
        {
          this.alertService.ErrorAlert(`${data.errorInfo.Message}`);
        }

      }, (error) => {
        this.alertService.ErrorAlert(error);
      });
  }

  ActivateCustomer(CardCode: string) {
    if(!this.G_ActivateCustomer)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }
    
    this.blockUI.start();
    this.bpService.activateCustomer(CardCode)
      .subscribe((data: BaseResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
          this.removeActivatedCustomer(CardCode);
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(error);
      });
  }

  removeActivatedCustomer(cardCode: string) {
    let index = this.BPList.findIndex(x => x.CardCode == cardCode);
    this.BPList.splice(index, 1);
  }
}
