import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

// MODELOS
import { Globals } from './../../../globals';
import { UsersAssingsResponse } from './../../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { UserService, AlertService } from '../../../services/index';

@Component({
  selector: 'app-users-assings',
  templateUrl: './users-assings.component.html',
  styleUrls: ['./users-assings.component.css']
})
export class UsersAssingsComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  userList: any[] = []; // lista de usuarios ya creados
  title = 'Asignación de usuarios';
  // PERMISOS
  G_CreateUserAssign: boolean; // permiso para la creacion de rutas
  G_EditUserAssign: boolean; // permiso para la edicion de rutas

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private fbg: FormBuilder,
    private router: Router,
    private uService: UserService,
    private globals: Globals,
  ) {
    this.G_CreateUserAssign = this.globals.perms.some(x => x.Name === 'G_CreateUserAssign');
    this.G_EditUserAssign = this.globals.perms.some(x => x.Name === 'G_EditUserAssign');
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.chargeUser();
  }

  chargeUser() {
    this.blockUI.start();
    this.uService.getUsersAssings()
      .subscribe((data: UsersAssingsResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.userList = data.userList;
        } else {
          this.alertService.WarningAlert(`${data.errorInfo.Message}`);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(`${error}`);
      });
  }

  confPage(id) {
    this.router.navigate(['userAssingsconf/' + id]);
  }

}
