import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private localStorageService;

  constructor() {
    this.localStorageService = localStorage;
  }

  // coloca los datos de la sesion actual, token y expiracion, usuario logueado
  setCurrentSession(currentUser): void {
    this.localStorageService.setItem('currentUser', JSON.stringify(currentUser));
  }

  // remueve la sesion actual
  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
  }

  // obtiene el token de la sesion actual
  getCurrentSession(): string {
    return this.localStorageService.getItem('currentUser');
  }

  // coloca los datos de la sesion actual, token y expiracion, usuario logueado
  setSelectedCompany(companyName, companyId): void {
    const SelectedCompany = {
      'companyName': companyName,
      'companyId': companyId
    };
    this.localStorageService.setItem('SelectedCompany', JSON.stringify(SelectedCompany));
  }

  // remueve la sesion actual
  removeSelectedCompany(): void {
    this.localStorageService.removeItem('SelectedCompany');
  }

  // obtiene el token de la sesion actual
  getSelectedCompany(): string {
    return this.localStorageService.getItem('SelectedCompany');
  }

  set(key: string, value: any) {
    this.localStorageService.setItem(key, JSON.stringify(value));
  }

  get(key: string) {
    return JSON.parse(this.localStorageService.getItem(key));
  }

  delete(key: string) {
    this.localStorageService.removeItem(key);
  }

  GetReportManagerURL(){
    let currentUser = JSON.parse(this.getCurrentSession());
    return currentUser['ReportManagerURL'];
  }

  getQuickAccess() {
    let accessList = localStorage.getItem('AccessList');
    if (!accessList) return [];

    return JSON.parse(accessList);
  }

  setQuickAccessList(accessList: any[]) {
    localStorage.removeItem('AccessList');

    localStorage.setItem('AccessList', JSON.stringify(accessList));
  }

}
