import { Component, OnInit, ViewChild } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { GeoRolsService, AlertService, UserService } from 'src/app/services';
import { GeoRol, GeoConfig, UserAssignWithName } from 'src/app/models';
import { Globals } from 'src/app/globals';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-geo-rols',
  templateUrl: './geo-rols.component.html',
  styleUrls: ['./geo-rols.component.css']
})
export class GeoRolsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('ngbTabset') tabset;
  rolModal: NgbModalRef;

  geoRols: GeoRol[];
  geoRolInEdition: GeoRol;
  geoRolToAssign: number;
  userEditingGeoRol: number;

  allGeoConfigs: GeoConfig[];
  availableGeoConfigs: GeoConfig[];
  assignedGeoConfigs: GeoConfig[];
  geoRolFilter: number;
  geoRolName: string;
  users: UserAssignWithName[];

  //PERMISOS
  G_DltGeoRol: boolean;
  G_UptGeoRol: boolean;
  G_CrtGeoRol: boolean;

  G_UnassignGeoPerms: boolean;
  G_AssignGeoPerms: boolean;
  G_AssignGeoRol: boolean;
  G_UnassignGeoRol: boolean;
  constructor(private modalService: NgbModal,
    private geoRolsService: GeoRolsService,
    private alertService: AlertService,
    private userService: UserService,
    private globals: Globals,
    ) { }

  ngOnInit() {
    this.getGeoRols();
    this.getGeoConfigs(0);
    this.GetUserAssignsWithName();

    this.G_DltGeoRol = this.globals.perms.some(p => p.Name === 'G_DltGeoRol');
    this.G_UptGeoRol = this.globals.perms.some(p => p.Name === 'G_UptGeoRol');
    this.G_CrtGeoRol = this.globals.perms.some(p => p.Name === 'G_CrtGeoRol');

    this.G_UnassignGeoPerms = this.globals.perms.some(p => p.Name === 'G_UnassignGeoPerms'),
    this.G_AssignGeoPerms = this.globals.perms.some(p => p.Name === 'G_AssignGeoPerms');

    this.G_AssignGeoRol = this.globals.perms.some(p => p.Name === 'G_AssignGeoRol'); 
    this.G_UnassignGeoRol = this.globals.perms.some(p => p.Name === 'G_UnassignGeoRol'); 
  }

  getGeoRols() {
    this.blockUI.start();

    this.geoRolsService.GetGeoRols().subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.geoRols = response.Georols;
        this.geoRolFilter = this.geoRols[0].Id;
      } else {
        console.log(response.errorInfo.Message);
      }
    }, err => {
      this.blockUI.stop();
      console.log(err);
    });
  }

  onTabChange(event) {
    if (event.nextId === 'tabCrud') this.getGeoConfigs(this.geoRolFilter, false);
    else this.geoRolInEdition = null;
  }

  onClickAddGeoPerms(geoRol: GeoRol) {
    this.tabset.activeId = 'tabCrud';
    this.geoRolFilter = geoRol.Id;
    this.geoRolInEdition = { ...geoRol };

    this.getGeoConfigs(this.geoRolInEdition.Id, false);
  }

  onClickEditRol(geoRol: GeoRol, modalContent: any) {
    this.geoRolInEdition = { ...geoRol };
    this.geoRolName = this.geoRolInEdition.Name;
    this.rolModal = this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  onClickSaveGeoRol() {

    if((this.geoRolInEdition && !this.G_UptGeoRol) ||
      (!this.geoRolInEdition && !this.G_CrtGeoRol))
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    if (!this.geoRolInEdition)
      this.geoRolInEdition = {
        Id: 0,
        Name: this.geoRolName
      };
    else this.geoRolInEdition.Name = this.geoRolName;

    this.blockUI.start();
    this.geoRolsService.handlePostOrPutGeoRol(this.geoRolInEdition).subscribe(response => {
      this.geoRolInEdition = null;
      this.geoRolName = null;
      this.blockUI.stop();

      if (response.result) {
        this.getGeoRols();
        this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
      }
      else this.alertService.ErrorAlert(response.errorInfo.Message);
    }), err => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(err);
    };
    this.onClickDismissModal();
  }

  onClickDismissModal() {
    this.rolModal.close();
    this.geoRolName = null;
    this.geoRolInEdition = null;
  }

  getGeoConfigs(geoRolId: number, loadingAllGeoConfigs = true) {
    this.blockUI.start();
    this.geoRolsService.GetGeoConfigs(geoRolId).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        if (loadingAllGeoConfigs) this.allGeoConfigs = response.GeoConfigs;
        else {
          this.assignedGeoConfigs = response.GeoConfigs;
          this.getAvailableGeoConfigs();
        }
      } else {
        this.assignedGeoConfigs = [];
        this.getAvailableGeoConfigs();
        console.log(response.errorInfo.Message);
      }

    }, err => {
      this.blockUI.stop();
      console.log(err);
    });
  }

  getAvailableGeoConfigs() {
    if (!this.assignedGeoConfigs || this.assignedGeoConfigs.length === 0) this.availableGeoConfigs = this.allGeoConfigs.slice();
    else this.availableGeoConfigs = this.allGeoConfigs.filter(x => !this.assignedGeoConfigs.some(y => y.Id === x.Id));
  }

  unassignGeoConfig(index: number) {
    if(!this.G_UnassignGeoPerms)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    let removedGeoConfigs = this.assignedGeoConfigs.splice(index, 1);

    this.availableGeoConfigs.push(removedGeoConfigs[0]);
  }

  assignGeoConfig(index: number) {
    if(!this.G_AssignGeoPerms)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    let removedGeoConfigs = this.availableGeoConfigs.splice(index, 1);

    this.assignedGeoConfigs.push(removedGeoConfigs[0]);
  }

  assignAllGeoConfigs() {
    if(!this.G_AssignGeoPerms)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }
    this.availableGeoConfigs = [];
    this.assignedGeoConfigs = this.allGeoConfigs.slice();
  }

  removeAllGeoConfigs() {
    if(!this.G_UnassignGeoPerms)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }
    this.assignedGeoConfigs = [];
    this.availableGeoConfigs = this.allGeoConfigs.slice();
  }

  onClickSaveGeoConfigs() {
    this.blockUI.start();
    this.geoRolsService.PostGeoConfigs(this.geoRolInEdition ? this.geoRolInEdition.Id : this.geoRolFilter, this.assignedGeoConfigs).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
      } else {
        this.alertService.ErrorAlert(`Ocurrió un error guardando los permisos geo ${response.errorInfo.Message}`);
      }
    }, err => {
      this.blockUI.stop();
      this.alertService.ErrorAlert(err);
    });
  }

  onClickNewGeoRol(modalContent: any) {
    this.rolModal = this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  async onClickDeleteGeoRol(geoRolId: number, index: number) {
    if(!this.G_DltGeoRol)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    let result = await this.alertService.confirmationAlert(`¿Eliminar rol geo?`, '¿Estas seguro que deseas eliminar el rol geo?', 'Eliminar');

    if (result) {
      this.blockUI.start();

      this.geoRolsService.deleteGeoRol(geoRolId).subscribe(response => {
        this.blockUI.stop();

        if (response.result) {
          this.geoRols.splice(index, 1);
          this.alertService.ToastSuccessAlert('Proceso finalizado exitosamente');
        } else {
          this.alertService.ErrorAlert(`Ocurrió un error eliminando el rol geo ${response.errorInfo.Message}`);
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.ErrorAlert(err);
      });
    }
  }

  GetUserAssignsWithName() {
    this.userService.GetUserAssignsWithName().subscribe(response => {
      if (response.result) {
        this.users = response.Users;
      } else {
        this.users = [];
        console.log(response.errorInfo.Message);
      }
    }, err => {
      console.log(err);
    });
  }

  getGeoRolNameById(geoRolId: number): string {
    let geoRol = this.geoRols.find(x => x.Id === geoRolId);

    if (!geoRol) return 'Sin asignación';

    return geoRol.Name;
  }


  onClickUserGeoRol(user: UserAssignWithName, assignGeoRol: any) {
    if(!this.G_AssignGeoRol)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }
    this.geoRolToAssign = user.GeoRolId;
    this.userEditingGeoRol = user.Id;
    this.rolModal = this.modalService.open(assignGeoRol, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  onClickPutUserGeoRol() {
    this.onClickDismissModal();
    this.blockUI.start();

    this.userService.PutUserGeoRol(this.userEditingGeoRol, this.geoRolToAssign).subscribe(response => {
      this.blockUI.stop();
      this.geoRolToAssign = null;
      this.userEditingGeoRol = null;
      if (response.result) {
        this.alertService.ToastSuccessAlert('Proceso finalizado eexitosamente');
        this.GetUserAssignsWithName();
      } else {
        this.alertService.ToastSuccessAlert(`Ocurrió un error editando el rol geo del usuario ${response.errorInfo.Message}`);
      }
    }, err => {
      this.blockUI.stop();
      this.geoRolToAssign = null;
      this.userEditingGeoRol = null;
      this.alertService.ErrorAlert(err);
    });
  }

  UnassignGeoRol(_user: UserAssignWithName): void
  {
    if(!this.G_UnassignGeoRol)
    {
      this.alertService.ToastInfoAlert("No tienes permisos para realizar esta acción");
      return;
    }

    this.blockUI.start("");

    this.geoRolsService.UnassignGeoRol(_user.Id)
    .pipe(finalize(() => this.blockUI.stop()))
    .subscribe({
      next: (callback) => {
        if(callback.result)
        {
          if(callback.errorInfo)
          {
            this.alertService.ToastInfoAlert(callback.errorInfo.Message);
          }
          else
          {
            this.alertService.ToastSuccessAlert("Se eliminó la asignación de geo rol");
            this.GetUserAssignsWithName();
          }
        }
        else
        {
          this.alertService.ErrorAlert(callback.errorInfo.Message);
        }
      }
    });
  }
}
