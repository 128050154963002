import { Component, OnInit, Type } from "@angular/core";
import {
  AuthenticationService,
  CommonService,
  CompanyService,
  PermissionsService,
  RptManagerService,
} from "./services";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";

import {
  PermissionsSelectedResponse,
  IPermissionsSelectedModel,
  AppConstants,
  MenuOption,
} from "src/app/models";
import { Globals } from "./globals";
import { finalize, first } from "rxjs/operators";
import { Menu } from "./models/constantes";
import { GoogleLoadScriptService } from './services/google-load-script.service';
import { StorageService } from './services/storage.service';
import { AlertService } from './services/alert.service';
import { ErrorInfo } from './models/responses';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  envName: string = environment.envName;
  isProduction: boolean = environment.production;
  @BlockUI() blockUI: NgBlockUI;
  title = "ClvsRouteUI";
  opened = false;
  dock = true;
  showBackdrop = false;
  closeOnBackdrop = false;
  currentSession: any;
  sidebarMode: string = "push";
  perms: IPermissionsSelectedModel[];

  menu: MenuOption[];
  reportsSelected: boolean;
  optionsWithReports: MenuOption[];
  constructor(
    private router: Router,
    private deviceDetector: DeviceDetectorService,
    private authService: AuthenticationService,
    private permsSerive: PermissionsService,
    private globals: Globals,
    private rptManagerService: RptManagerService,
    private companyService: CompanyService,
    private storage: StorageService,
    private loadScriptService: GoogleLoadScriptService,
    private alertService: AlertService,
    private commonService: CommonService
  ) {
    this.authService.currentUser.subscribe((x) => {
      this.currentSession = x;

      if (this.currentSession && !this.perms) {
        this.getUserPerms();
      }
    });
    this.globals.sidebarToggler.subscribe((x) => (this.opened = x));
    this.setMenu();
  }
  ngOnInit(): void {
    if(this.storage.getCurrentSession())
      this.loadScriptService.LoadScript();

    this.reportsSelected = false;

    this.optionsWithReports = [];
  }

  setMenu() {
    this.menu = Menu;

    if (this.deviceDetector.isMobile()) {
      this.sidebarMode = "over";
      this.showBackdrop = true;
      this.closeOnBackdrop = true;
    }
  }

  onClickMenuOption(menuOption: MenuOption) {
    
    this.menu.forEach((x) => {
      x.Selected = false;

      if (x.Children.length > 0) {
        x.Children.forEach((y) => (y.Selected = false));
      }
    });

    switch (menuOption.Name) {
      case "Reportes":
        this.onClickAppReports(menuOption);
        break;
      case "Salir":
        this.logOut();
        break;
      default:
        menuOption.Selected = true;
        if (menuOption.Route) this.router.navigateByUrl(menuOption.Route);
        break;
    }
  }

  logOut() {
    this.opened = false;
    this.authService.logout();
  }

  getUserPerms() {
    this.permsSerive
      .getPermsByUser()
      .subscribe((data: PermissionsSelectedResponse) => {
        if (data.result) {
          this.perms = data.PermissionsList ? data.PermissionsList : [];
          this.globals.perms = data.PermissionsList ? data.PermissionsList : [];
          this.setVisibleMenuOptions();
          this.setVisibleSubMenuOptions();
        }
      });
  }

  getMenuOptions() {
    return this.menu.filter((x) => x.Visible);
  }

  getSubmenuOptions(menuOptions: MenuOption) {
    return menuOptions.Children.filter((x) => x.Visible);
  }

  setVisibleMenuOptions() {
    this.menu.forEach((x) => {
      if (!x.Visible) {
        let requiredPerm = this.perms.some((y) => y.Name === x.RequiredPerm);
        let hasOptChildPerms = x.Children.some(so => this.perms.some(p => p.Name === so.RequiredPerm));
        if (requiredPerm || hasOptChildPerms) x.Visible = true;
      }
    });
  }

  setVisibleSubMenuOptions() {
    this.menu.forEach((m) => {
      m.Children.forEach((s) => {
        if (!s.Visible) {
          let requiredPerm = this.perms.find((p) => p.Name === s.RequiredPerm);
          if (requiredPerm) s.Visible = true;
        }
      });
    });
  }

  openedChange($event) {
    this.globals.sidebarToggler.next($event);
  }

  onClickAppReports(option: MenuOption) {
    if (!AppConstants.KeyReportManager) 
    {
      this.blockUI.start('Obteniendo key de report manager...');

      this.companyService
        .GetKeyReportManager()
        .pipe(first(), finalize(()=> this.blockUI.stop()))
        .subscribe((response) => {
          if (response.result) {
            if(response.errorInfo)
            {
              this.alertService.ToastInfoAlert(response.errorInfo.Message);
            }
            else
            {
              AppConstants.KeyReportManager = response.Data;
              this.GetApplicationReports(option);
            }
          }
          else
          {
            this.alertService.ToastErrorAlert(response.errorInfo.Message);
          }
      });
    }
    else
    {
      if(!this.reportsSelected || !option || (option && option.Children.length <= 0))
      {
        this.GetApplicationReports(option);
      }
    }

    this.reportsSelected = !this.reportsSelected;
  }

  GetApplicationReports(option: any) {
    this.blockUI.start("Obteniendo reportes...");
    this.rptManagerService
      .GetReports()
      .pipe(first())
      .subscribe(
        (response) => {
          option.Children = [];
          if(response.Result)
          {
            this.rptManagerService.AddReportsToMenu(
              response.Reports,
              option
            );
            this.blockUI.stop();
            if(response.Reports && response.Reports.length > 0){
              this.alertService.ToastSuccessAlert("Reportes obtenidos con éxito");
            } else {
              this.alertService.ToastInfoAlert("No existe reportes registrados");
            }
          } else {
            this.alertService.WarningAlert(response.ErrorInfo.Message);
          }
        },
        (err) => {
          console.error(err);
          this.blockUI.stop();
        }
      );
  }
  
}
