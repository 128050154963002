import { LazyMapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';
import { RouteLinesMobileModel } from '../models/route';

@Injectable({
  providedIn: 'root'
})
export class RouteCalculationService {

  constructor() {  }

  async CalculateRoute(routeLines: RouteLinesMobileModel[]): Promise<google.maps.DistanceMatrixResponse[]>{
    let matrixResults: google.maps.DistanceMatrixResponse[] = [];
    for(let i = 0; i < routeLines.length-1 && routeLines.length >= 2; i++){
      let startPoint = { latitude: routeLines[i].Latitude, logitude: routeLines[i].Longitude };
      let endPoint = { latitude: routeLines[i+1].Latitude, logitude: routeLines[i+1].Longitude };
      let request  = await this.CalculateTwoPoints(startPoint, endPoint);
      request['originLine'] = { address: routeLines[i].Address, latitude: routeLines[i].Latitude, longitude: routeLines[i].Longitude };
      request['destinationLine'] = { address: routeLines[i+1].Address, latitude: routeLines[i+1].Latitude, longitude: routeLines[i+1].Longitude };
      matrixResults.push(request);
    }
    return matrixResults;
  }

  CalculateTwoPoints(startPoint, endPoint): Promise<google.maps.DistanceMatrixResponse>{
    const service = new google.maps.DistanceMatrixService();
    const origin = { lat: Number(startPoint.latitude), lng: Number(startPoint.logitude) };
    const detination = { lat: Number(endPoint.latitude), lng: Number(endPoint.logitude) };

    const request = {
      origins: [origin],
      destinations: [detination],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false
    };

    return service.getDistanceMatrix(request);
  }
}